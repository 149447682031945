/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useCallback } from "react"
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useDropzone} from 'react-dropzone'

import "react-datepicker/dist/react-datepicker.css";

const ModalFranqueadosAdm = (
    {
        tipoModal,
        openModal,
        setOpenModal,
        dados,
        setDados,
        onSubmit
    }
) => {

    const [openEditor, setOpenEditor] = useState(false)

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result
        //print base64 string
        const base64String = btoa(String.fromCharCode(...new Uint8Array(binaryStr)))
        setDados({...dados, midia: base64String})
        }
        reader.readAsArrayBuffer(file)
        })
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop})
    
    const getExtension = (base64) => {
        if(base64 === "") {
        const ext = base64.charAt(0)
        switch (ext) {
            case '/' : return 'jpg'
            case 'i' : return 'png'
            case 'g' : return 'gif'
            case 'b' : return 'bmp'
            default: return 'jpg'
        }
        }
    }
        

    return (
        <>
        <Modal isOpen={openEditor} style={{width: '800px', minWidth: '800px'}} className='modal-dialog-centered'>
            <ModalBody>
                <Row>
                    <Col sm='12'>
                        <Editor
                        editorState={dados.script}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={(e) => setDados({...dados, script: e})}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={() => setOpenEditor(false)}>Salvar</Button>
                <Button color='secondary' onClick={() => setOpenEditor(false)}>Cancelar</Button>
            </ModalFooter>
        </Modal>

        <Modal isOpen={openModal} style={{width: '800px', minWidth: '800px'}} className='modal-dialog-centered'>
            <ModalHeader>
                {tipoModal} Post
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm='6'>
                    <Row>
                    <Col sm='12'>
                        <Label>Título</Label>
                        <Input 
                        type='text' 
                        value={dados.titulo}
                        onChange={(e) => setDados({...dados, primeiro_nome: e.target.value})} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col sm='12'>
                        <Label>Texto</Label>
                        <Input
                        type='textarea'
                        rows='8'
                        value={dados.descricao}
                        onChange={(e) => setDados({...dados, ddd: e.target.value})}
                        />
                    </Col>
                </Row>
                </Col>
                <Col sm='6'>
                <Row>
                    <Col sm='12'>
                        <Label>Imagem Capa</Label>
                        {dados.midia === undefined ? <div {...getRootProps()} style={{
                            width: '100%',
                            height: '18.7rem',
                            border: '1px solid #ccc',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            borderRadius: '10px',
                            cursor: 'pointer'
                        
                        }}>
                            <input {...getInputProps()} />
                            <p>Clique aqui ou arraste uma imagem para cá</p>
                        </div>   : <div style={{
                            width: '100%',
                            height: '18.7rem',
                            border: '1px solid #ccc',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            borderRadius: '10px',
                            cursor: 'pointer',
                            padding: '10px'
                        }}>
                            <img onClick={() => setDados({...dados, midia: undefined})} src={`data:image/${getExtension(dados.midia)};base64,${dados.midia}`} style={{width: '100%', height: '100%', objectFit: 'cover'}} />
                        </div>

                        }
                    </Col>
                </Row>
                </Col>
                </Row>
                <br />
                <Row>
                    <Col sm='12'>
                        <Label>Script</Label>
                        <Input
                        type='text'
                        value={dados.script}
                        placeholder="Clique para editar o script"
                        readOnly={true}
                        onClick={() => setOpenEditor(true)}
                        />
                    </Col>                   
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={() => onSubmit()}>Salvar</Button>
                <Button color='secondary' onClick={() => setOpenModal(false)}>Cancelar</Button>
            </ModalFooter>
        </Modal>            
        </>
    )
}

export default ModalFranqueadosAdm;