/* eslint-disable jsx-a11y/alt-text */

import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Col, Row, Label, Input, Spinner } from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";


const ModalPlanos = (
    {
        tipoModal,
        openModal,
        setOpenModal,
        dados,
        setDados,
        onSubmit,
        dataForm,
        setDataForm,
        spinner
    }
) => {

    return (
        <>
            <Modal isOpen={spinner} className="modal-dialog-centered">
                <ModalBody>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "20px",
                            fontSize: "20px",
                            fontWeight: "bold",
                        }}
                    >
                        <Spinner animation="border" variant="primary" />&nbsp; &nbsp;  Aguarde...
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={openModal} style={{ width: '600px', minWidth: '600px' }} className='modal-dialog-centered'>
                <ModalHeader>
                    {tipoModal} Plano
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm='12'>
                            <Label>Descrição</Label>
                            <Input
                                type='text'
                                value={dataForm.descricao}
                                onChange={(e) => setDataForm({ ...dataForm, descricao: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm='4'>
                            <Label>Preço</Label>
                            <Input
                                type='number'
                                maxLength={2}
                                minLength={2}
                                value={dataForm.preco}
                                onChange={(e) => setDataForm({ ...dataForm, preco: parseFloat(e.target.value) })}
                            />
                        </Col>
                        <Col sm='4'>
                            <Label>Preço Criança</Label>
                            <Input
                                type='number'
                                maxLength={2}
                                minLength={2}
                                value={dataForm.preco_crianca}
                                onChange={(e) => setDataForm({ ...dataForm, preco_crianca: parseFloat(e.target.value) })}
                            />
                        </Col>
                        <Col sm='4'>
                            <Label>Preço Adulto</Label>
                            <Input
                                type='number'
                                maxLength={2}
                                minLength={2}
                                value={dataForm.preco_adulto}
                                onChange={(e) => setDataForm({ ...dataForm, preco_adulto: parseFloat(e.target.value) })}
                            />
                        </Col>
                    </Row>
                    {/* <br />
                <Row>                    
                    <Col sm='12'>
                        <Label>ID Ciclopay</Label>
                        <Input
                        type='number'
                        maxLength={2}
                        minLength={2}
                        value={dataForm.ciclopay_id}
                        onChange={(e) => setDataForm({...dataForm, ciclopay_id: parseInt(e.target.value)})}
                        />
                    </Col>
                </Row> */}
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={() => onSubmit()}>Salvar</Button>
                    <Button color='secondary' onClick={() => setOpenModal(false)}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ModalPlanos