import React, { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { PaginadorCidade } from '../../service/utils';

const AsyncSelectCidade = ({ 
handleOnChangeSelect,
  placeholder,
  defaultValue,
  id,
  estado,
  className,
  tipo
}) => {

  const [filter, setFilter] = useState();
  const [noOptionsMessage, setNoOptionsMessage] = useState('')

  useEffect(() => {
    setFilter("");
  }, []);

  const onChange = option => {
    
    handleOnChangeSelect(option);
  }

  // Options - Select
  const loadOptions = async (inputValue, loadedOptions, { page }) => {
    try {
      console.log('inputValue', inputValue)
      const data = {
        estado_id: estado,      
        nome: inputValue,
        apenas_credenciado: tipo,
        limite: 10,
        pagina: 1
      }
      const response = await PaginadorCidade(data, 10, page);
      

      const options = response.data.content.map(item => {
        return {
          value: item.id,
          label: item.nome          
        }
      })
      
      return {
        options,
        hasMore: response.data.currentPage < response.data.countPages,
        additional: {
          page: inputValue ? 1 : page + 1
        }
      }
    } catch (e) {
      return {
        options: []
      }
    }
  }
  
  return (
    <div style={{ width: '100%'}}>
       <AsyncPaginate
        key={JSON.stringify(filter)}
        id={id}
        isClearable={true}
        className={className}
        classNamePrefix="select"
        value={defaultValue}
        loadingMessage={() => 'Carregando dados'}
        noOptionsMessage={() => noOptionsMessage || 'Nenhum registro encontrado'
      }
        loadOptions={loadOptions}
        onChange={onChange}
        isSearchable={true}
        placeholder="Cidade"
        additional={{
          page: 1
        }}
        styles={{
          input:provided => ({ ...provided, zIndex: 9999, color: '#000', width: '250px' }),
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
      /> 
    </div>
  )
}
export default AsyncSelectCidade;