/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useCallback } from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Progress
} from "reactstrap";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDropzone } from "react-dropzone";
import { S3 } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";

import "react-datepicker/dist/react-datepicker.css";

const ModalUsuarios = ({
  tipoModal,
  openModal,
  setOpenModal,
  dados,
  setDados,
  onSubmit,
}) => {
  const [openEditor, setOpenEditor] = useState(false);
  const [porcentageUp, setPorcentageUp] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [fileImage, setFileImage] = useState("");
  const [extension, setExtension] = useState("");
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );
  

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        setFileImage(file);
        setExtension(file.name.split(".")[1]);
        console.log("EXTENSÃO", file.name.split(".")[1])
        setDados({ ...dados, midia: `blog/${file.name}` });
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const getExtension = (base64) => {
    
    if (base64 !== "") {
      const ext = base64.charAt(0);
      switch (ext) {
        case "/":
          return "jpg";
        case "i":
          return "png";
        case "g":
          return "gif";
        case "b":
          return "bmp";
        default:
          return "jpg";
      }
    }
  };

  const uploadFile = async () => {
    setSpinner(true);
    const target = {
      Bucket: "mixdasaude-s31",
      uploadId: "qweqwe",
      Key: `blog/${fileImage.name}`,
      Body: fileImage,
      ContentType: `image/${getExtension(dados.midia)}`,
      ACL: "public-read"
      
      
      
    };

    const salvaImagem = new Upload({
      client: new S3({
        region: "sa-east-1",
        credentials: {
          accessKeyId: process.env.REACT_APP_S3_KEY,
          secretAccessKey: process.env.REACT_APP_S3_PASSWORD,
        },
      }),
      leavePartsOnError: false,
      params: target,
    });

    salvaImagem.on("httpUploadProgress", (progress) => {
      const porcentage = Math.round((progress.loaded / progress.total) * 100);
      setPorcentageUp(porcentage);
    });
    salvaImagem.done().then((data) => {
      setDados({ ...dados, midia: `blog/${fileImage.name}` });
      setSpinner(false);
      onSubmit(`blog/${fileImage.name}`);
    });
  };

  const handleEditor = () => {
    const text = draftToHtml(convertToRaw(editorState.getCurrentContent()))
  
    setDados({ ...dados, html: text });
    setOpenEditor(false);
  };

  return (
    <>
    <Modal isOpen={spinner} className="modal-dialog-centered">
        <ModalBody>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Row>
              <Col sm="12">
                <h3>Carregando ... </h3>
                <Progress
                  striped
                  className="progress-bar-primary"
                  value={porcentageUp}
                >
                  {" "}
                  {porcentageUp} %{" "}
                </Progress>
              </Col>              
            </Row>
            <Row>
              
            </Row>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openEditor}
        style={{ width: "800px", minWidth: "800px" }}
        className="modal-dialog-centered"
      >
        <ModalBody>
          <Row>
            <Col sm="12">
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "fontFamily",
                    "list",
                    "textAlign",
                    "colorPicker",
                    "link",
                    "embedded",
                    "emoji",
                    "image",
                    "remove",
                    "history",
                  ],
                  inline: {
                    options: ["bold", "italic", "underline", "strikethrough"],
                  },
                  blockType: {
                    options: [
                      "Normal",
                      "H1",
                      "H2",
                      "H3",
                      "H4",
                      "H5",
                      "H6",
                      "Blockquote",
                      "Code",
                    ],
                  },
                  fontSize: {
                    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48],
                  },
                  fontFamily: {
                    options: [
                      "Arial",
                      "Georgia",
                      "Impact",
                      "Tahoma",
                      "Times New Roman",
                      "Verdana",
                    ],
                  },
                  list: {
                    options: ["unordered", "ordered"],
                  },
                  textAlign: {
                    options: ["left", "center", "right", "justify"],
                  },
                  colorPicker: {
                    colors: [
                      "rgb(97,189,109)",
                      "rgb(26,188,156)",
                      "rgb(84,172,210)",
                      "rgb(44,130,201)",
                      "rgb(147,101,184)",
                      "rgb(71,85,119)",
                      "rgb(204,204,204)",
                      "rgb(65,168,95)",
                      "rgb(0,168,133)",
                      "rgb(61,142,185)",
                      "rgb(41,105,176)",
                      "rgb(85,57,130)",
                      "rgb(40,50,78)",
                      "rgb(0,0,0)",
                      "rgb(247,218,100)",
                      "rgb(251,160,38)",
                      "rgb(235,107,86)",
                      "rgb(226,80,65)",
                      "rgb(163,143,132)",
                      "rgb(239,239,239)"
                    ],
                  },
                }
                }
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleEditor()}>
            Salvar
          </Button>
          <Button color="secondary" onClick={() => setOpenEditor(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={openModal}
        style={{ width: "800px", minWidth: "800px" }}
        className="modal-dialog-centered"
      >
        <ModalHeader>{tipoModal} Post</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="6">
              <Row>
                <Col sm="12">
                  <Label>Título</Label>
                  <Input
                    type="text"
                    value={dados.titulo}
                    onChange={(e) =>
                      setDados({ ...dados, titulo: e.target.value })
                    }
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm="12">
                  <Label>Texto</Label>
                  <Input
                    type="textarea"
                    rows="8"
                    value={dados.conteudo}
                    onChange={(e) =>
                      setDados({ ...dados, conteudo: e.target.value })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col sm="6">
              <Row>
                <Col sm="12">
                  <Label>Imagem Capa</Label>
                  {extension === "" ? (
                    <div
                      {...getRootProps()}
                      style={{
                        width: "100%",
                        height: "18.7rem",
                        border: "1px solid #ccc",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <input {...getInputProps()} />
                      <p>Clique aqui ou arraste uma imagem para cá</p>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "18.7rem",
                        border: "1px solid #ccc",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        borderRadius: "10px",
                        cursor: "pointer",
                        padding: "10px",
                      }}
                    >
                      {extension === "mp4" ? (
                        <video
                          src={URL.createObjectURL(fileImage)}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          controls
                        />
                      ) : (
                      <img
                        onClick={() => {setDados({ ...dados, midia: undefined })
                        setExtension("")}}
                        src={fileImage ? URL.createObjectURL(fileImage) : dados.midia}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="12">
              <Label>Script</Label>
              <Input
                type="text"
                value={dados.html}
                placeholder="Clique para editar o script"
                readOnly={true}
                onClick={() => setOpenEditor(true)}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => uploadFile()}>
            Salvar
          </Button>
          <Button color="secondary" onClick={() => setOpenModal(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalUsuarios;
