import api from "../api";


export const Paginador = async (body) => {
        return api
          .post(`post/paginate`, body, {
            headers: {
              "Content-Type": "application/json"              
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };
        
        export const SavePost = async (body) => {
            
          return api
          .post(`post/save`, body, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };

export const UpdatePost = async (body) => {
        return api
          .put(`post/update`, body, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };

export const GetPost = async (post) => {
        return api
          .get(`post/${post}`, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };

export const RemovePost = async (id) => {
        console.log(id);
        return api
          .delete(`post/remove/${id}`,  {
            
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };

        


