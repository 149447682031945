import HomePage from "../../pages/home/home";

const Home = () => {

if (localStorage.getItem('token') || localStorage.getItem('user')) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.reload();
}



    return (
        <>
        <HomePage />
        </>
    );
    };

export default Home;