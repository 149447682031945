import api from "../api";


export const PaginadorDash = async (body) => {
  console.log(body);
  return api
    .post(`plano/paginateDash`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const Paginador = async () => {
  const body = {
    page: 1,
    itensPerPage: 10,
    descricao: null
  }
  return api
    .post(`plano/paginate`, body, {
      headers: {
        "Content-Type": "application/json",

      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const SavePlanos = async (body) => {
  return api
    .post(`plano/save`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const EditPlano = async (body) => {
  return api
    .put(`plano/update`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const BuscaPlanoId = async (id) => {
  return api
    .get(`plano/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};


export const RemovePlano = async (id) => {
  return api
    .delete(`plano/remove/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const DesativaPlano = async (id) => {
  return api
    .get(`/plano/desativar/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};




