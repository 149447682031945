
const Stepper = ({ step, setStep }) => {
  const steps = [
    {
      title: "Step 1",
      content: "Step 1 Content",
    },
    {
      title: "Step 2",
      content: "Step 2 Content",
    },
    {
      title: "Step 3",
      content: "Step 3 Content",
    },
    {
      title: "Step 4",
      content: "Step 3 Content",
    },
    {
      title: "Step 5",
      content: "Step 3 Content",
    },
    {
      title: "Step 6",
      content: "Step 3 Content",
    },
  ];

  return (
    <>
      <div className={"stepper"}>
        {steps.map((_, index) => (
          <>
            <div
              onClick={() => index <= step && step !== 0 && setStep(step - 1)}
              className={index <= step ? "circle active" : "circle"}  
            >
              {index + 1}
            </div>
            <div
              className={index <= step ? "line active" : "line"}
              style={{ display: index === 5 ? 'none' : 'block' }}
            ></div>
          </>
        ))}
      </div>
    </>
  );
};

export default Stepper;
