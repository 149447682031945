/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { GetPost } from '../../../service/blog/index'
import { useNavigate } from 'react-router-dom';

const BlogPage = () => {

    const url_string = window.location.href;
    const url = new URL(url_string);
    const data = url.searchParams.get("id");
    console.log(data)
    const [result, setResult] = React.useState([])
    const navigate = useNavigate()
    const getBlog = async () => {

        GetPost(data)
        .then((res) => {
            setResult(res.data.salvo.html)
        })
        .catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        getBlog()
    }, [])

    
    return (
    <>
    <br/>
    <div style={{
        width: "85%",
        height: "100%",
        marginLeft: "100px",
        marginRight: "100px",
        
    }} className="content" dangerouslySetInnerHTML={{__html: result}}></div>
    </>
    )
    }

    export default BlogPage