import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import "react-datepicker/dist/react-datepicker.css";

const ModalAssinaturas = (
    {
        tipoModal,
        openModal,
        setOpenModal,
        dados,
        setDados,
        onSubmit
    }
) => {


    return (
        <>
        <Modal isOpen={openModal} style={{width: '600px', minWidth: '600px'}} className='modal-dialog-centered'>
            <ModalHeader>
                {tipoModal} Assinaturas
            </ModalHeader>
            <ModalBody>
            </ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={() => onSubmit()}>Salvar</Button>
                <Button color='secondary' onClick={() => setOpenModal(false)}>Cancelar</Button>
            </ModalFooter>
        </Modal>            
        </>
    )
}

export default ModalAssinaturas