/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Edit2, Filter, Trash2, UserPlus } from "react-feather";
import { toast } from "react-toastify";
import {
  Paginador,
  SaveUser,
  RemoveUser
} from "../../../service/users";

import { Card, CardBody, Row, Col, Button, Label, Input } from "reactstrap";
import ModalUsuarios from "./modal";

import SweetAlert2 from 'react-sweetalert2';

const Usuarios = () => {
  const [swalProps, setSwalProps] = useState({});
  const [pagina, setPagina] = useState(1);
  const [tipoModal, setTipoModal] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [dataForm, setDataForm] = useState({
    primeiro_nome: "",
    sobrenome: "",
    email: "",
    senha: "",
    ddd: 0,
    telefone: 0,
    confirma_senha: "",
    data_nascimento: ""
  });
  const [filter, setFilter] = useState([
    {
      page: pagina,
      itensPerPage: 10,
      descricao: null,
      email: null,
      cpf: null,
      nome: null
    },
  ]);
  const [dados, setDados] = useState([
    {
      primeiro_nome: "",
      sobrenome: "",
      email: "",
      ddd: 1,
      telefone: 1,
      confirma_senha: "",
      data_nascimento: "",
    },
  ]);

  const [tooltipId, setTooltipId] = useState(null);

  const showTooltip = (id) => {
      setTooltipId(id);
  };

  const hideTooltip = () => {
      setTooltipId(null);
  };

  const handleOpenModal = (tipo) => {
    setTipoModal(tipo);
    setOpenModal(true);
  };

  function TrashClick(id){
    setSwalProps({
        show: true,
        title: 'Deseja remover o usuário?',
        text: "Não será possível reverter esta ação!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Não, cancelar!',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        reverseButtons: true,
        focusCancel: true,
        focusConfirm: false,

        preConfirm: () => {
            RemoverUsuario(id);
            setSwalProps({show: false});
        }
        
    }); 
}

  const RemoverUsuario = (id) => {
    const body = {
      id: id
    };
    RemoveUser(body).then((result) => {
      toast.success("Usuário removido com sucesso!");
      getUsers();
      }
  ).catch((error) => {
      console.log(error);
      toast.error("Erro ao remover o usuário!");
  }
  );
};

  const getUsers = () => {
    setOpenFilter(false);
    Paginador(filter).then((result) => {
        console.log(result.data);
        setDados(result.data.data); 
    }).catch((error) => {
        console.log(error);
        toast.error("Erro ao carregar os dados!");
    });
    };


  useEffect(() => {
    getUsers();
  }, []);

  const onSubmit = () => {
    console.log(dataForm);
    //colooca data de nascomento em formato BR
    SaveUser(dataForm).then((result) => {
        console.log(result.data);
        toast.success("Usuário cadastrado com sucesso!");
        setOpenModal(false);
        getUsers();
        }
    ).catch((error) => {
        console.log(error);
        toast.error("Erro ao cadastrar o usuário!");
    }   
    );
  };

  const columns = [
    {
      name: "Nome",
      selector: "primeiro_nome",
      sortable: true,
      cell: (row) => (
        <>
            {row.primeiro_nome} {row.sobrenome}
        </>
      ),
    },
    {
      name: "E-mail",
      selector: "email",
      sortable: true,
      cell: (row) => <>{row.email}</>,
    },
    {
      name: "Ações",
      selector: "acoes",
      sortable: true,
      right: true,
      cell: (row) => (
        <>
          {/* <Edit2 size={18} onClick={() => handleOpenModal("Editar")} /> */}
          <div onMouseOver={() => showTooltip('remover-' + row.id)} onMouseLeave={() => hideTooltip()}
              style={{ position: 'relative', display: 'inline-block', cursor: 'pointer', }
              }>
              <Trash2 style={{ cursor: "pointer" }} onClick={() => TrashClick(row.id)} size={18}></Trash2>
              {tooltipId === 'remover-' + row.id && (
                  <div className="tooltip-adm">Remover</div>
              )}
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <ModalUsuarios
        tipoModal={tipoModal}
        openModal={openModal}
        setOpenModal={setOpenModal}
        dataForm={dataForm}
        setDataForm={setDataForm}
        onSubmit={onSubmit}
      />
      <SweetAlert2 {...swalProps} didClose={
        () => setSwalProps({show: false})
      }/>
      <br />
      <Row>
        <Card>
          <CardBody>
            <Row>
              <Col sm="2">
                <h4>Usuários</h4>
              </Col>
              <Col
                sm="10"
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  alignItems: "right",
                }}
              >
                <Button
                  className="buttonSideFilter"
                  onClick={() => setOpenFilter(!openFilter)}
                >
                  <Filter size={18} />
                </Button>
                &nbsp;
                {/* <Button
                  size="sm"
                  className="buttonSide"
                  onClick={() => handleOpenModal("Criar")}
                >
                  <UserPlus size={18} />
                  &nbsp; Novo Usuário
                </Button> */}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
      <br />
      <div
        style={{
          display: openFilter ? "block" : "none",
          transitionTimingFunction: "ease-in-out",
          transitionDuration: "2s",
          transform: "translateY(-10px)",
        }}
      >
        <Row>
          <Card>
            <CardBody>
              <Row>
                <Col sm="1">
                  <h5>Filtros</h5>
                </Col>
                <Col sm="11">
                  <Row>
                    <Col sm="4">
                      <Label>Nome</Label>
                      <Input 
                      type="text"
                      value={filter.nome}
                      onChange={(e) => setFilter({...filter, nome: e.target.value})}
                      />
                    </Col>
                    <Col sm="4">
                      <Label>E-mail</Label>
                      <Input 
                      type="text"
                      value={filter.email}
                      onChange={(e) => setFilter({...filter, email: e.target.value})}                      
                      />
                    </Col>
                    <Col sm="4">
                      <Label>CPF</Label>
                      <Input 
                      type="text" 
                      value={filter.cpf}
                      onChange={(e) => setFilter({...filter, cpf: e.target.value})}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm="12">
                      <Button
                        size="sm"
                        onClick={() => getUsers()}
                        className="buttonSide"
                        style={{ float: "right", width: "100px" }}
                      >
                        Filtrar
                      </Button>
                      </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
      </div>

      <Row>
        <Card>
          <CardBody>
            <Row>
              <DataTable
                columns={columns}
                data={dados}
                pagination
                highlightOnHover
                responsive
                striped
              />
            </Row>
          </CardBody>
        </Card>
      </Row>
    </>
  );
};

export default Usuarios;
