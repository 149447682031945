import React from 'react'
import bgImage from '../../../../src/assets/images/bg/sobrenos.png'
import bgClinica1 from '../../../../src/assets/images/clinica1.png'
import bgClinica2 from '../../../../src/assets/images/clinica2.png'
import bgClinica3 from '../../../../src/assets/images/clinica3.png'
import { Row, Col } from "react-bootstrap";

const Sobre = () => {


    return (
        <>
            <div className="web" style={{ width: '100%', display: 'flex' }}>
                <div style={{
                    flexDirection: 'column',
                    width: '60%',
                    height: '70vh',
                    backgroundColor: '#1A4066',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    color: 'white',
                    fontSize: '2rem',
                    padding: '8rem 2rem 2rem 7rem',
                    fontFamily: 'Poppins',
                }}>
                    <div style={{
                        textAlign: 'left',
                        height: '70vh',
                        verticalAlign: 'middle',
                    }}>
                        A Mix da Saúde veio para te oferecer acesso a Saúde de qualidade de forma rápida e acessível
                    </div>
                </div>
                <div style={{
                    display: 'inline-block',
                    width: '40%',
                    height: '70vh',
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}>

                </div>
            </div>

            <div
                className="hero-app app"
                style={{
                    display: "inline-block",
                    width: "100%",
                    height: "22vh",
                    marginTop: '5rem',
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            ></div>
            <div className='hero-text-sobre-background app'>
                <div className='hero-text-sobre'>
                    A Mix da Saúde veio para te oferecer acesso a Saúde de qualidade de forma rápida e acessível
                </div>
            </div>

            <br />
            <br />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <div
                    className='sobre-text'
                    style={{
                        width: '70%',
                        textAlign: 'center',
                        fontFamily: 'Poppins',
                        fontSize: '1.3rem',
                        color: '#969696',
                    }}
                >
                    O alto custo para se ter acesso a saúde no Brasil, quer seja através dos planos de saúde ou de clínicas particulares, somado a demora no atendimento da rede pública tem dificultado o acesso a Saúde de grande parte da população. Enquanto isso a preocupação e a dor se toram comum nas vidas pessoas.  Em razão disso, criamos uma alternativa eficaz, que dá acesso a uma Saúde de qualidade, agilidade nos atendimentos e valores acessíveis para todos que buscam cuidar bem da Saúde.
                </div>
            </div>
            <br />
            <br />
            <div className="web" style={{
                display: 'flex',
                height: '75rem',
                justifyContent: 'center',
            }}>
                <div style={{ width: '70%', display: 'flex' }}>
                    <div style={{
                        flexDirection: 'column',
                        width: '50%',
                        height: '155vh',

                    }}>
                        <div style={{
                            textAlign: 'left',
                            height: '155vh',
                            verticalAlign: 'middle',
                        }}>
                            <div style={{
                                backgroundImage: `url(${bgClinica1})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                width: '100%',
                                height: '33%',
                            }}>

                            </div>
                            <div style={{
                                backgroundImage: `url(${bgClinica2})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                width: '100%',
                                height: '33%',
                            }}>
                            </div>
                            <div style={{
                                backgroundImage: `url(${bgClinica3})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                width: '100%',
                                height: '33%',
                            }}>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        flexDirection: 'column',
                        width: '50%',
                        height: '120vh',
                        paddingLeft: '2rem',

                    }}>
                        <div style={{
                            textAlign: 'left',
                            height: '120vh',
                            verticalAlign: 'middle',
                            fontFamily: 'Poppins',
                            fontSize: '1.3rem',
                            color: '#969696',
                        }}>
                            Somos um Sistema Alternativo de Saúde, possuímos parceria com as melhores clínicas, laboratórios e hospitais da região. Somos norteados pelo nosso amor pela saúde e por compartilhar desse amor com você, muito além do que vender benefícios, o que nos move diariamente é poder fazer cada vez mais para quem deseja cuidar da saúde de maneira rápida e com confiança.
                            <br />
                            <br />
                            Nosso portfólio conta com mais de 100 profissionais parceiros que são os melhores no seu ramo de atuação e que oferecerão atendimento de qualidade para você e sua família, além de condições de pagamento facilitadas.
                            <br />
                            <br />
                            Nosso objetivo é levar a você uma solução completa para que você encontre tudo o que  precisa de forma simples e prática. Possuímos uma rede de clínicas próprias, que facilitam aos nossos usuários realizar Consultas Médicas, Procedimentos Odontológicos, Exames Laboratoriais e Exames de Imagem, em único lugar! Vem ser MIX você também!
                        </div>
                    </div>
                </div>
            </div>
            <Row className="app" style={{ width: '80%', textAlign: "center", flexWrap: 'wrap', margin: "0rem 3rem" }}>
                <Col style={{ width: '100%' }}>
                    <div style={{
                        textAlign: 'left',
                        height: '65rem',
                        verticalAlign: 'middle',
                    }}>
                        <div style={{
                            backgroundImage: `url(${bgClinica1})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            width: '100%',
                            height: '20%',
                            marginBottom: "2rem"
                        }}>

                        </div>
                        <div style={{
                            backgroundImage: `url(${bgClinica2})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            width: '100%',
                            height: '20%',
                            marginBottom: "2rem"
                        }}>
                        </div>
                        <div style={{
                            backgroundImage: `url(${bgClinica3})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            width: '100%',
                            height: '20%',
                            marginBottom: "2rem"
                        }}>
                        </div>
                    </div>
                </Col>
                <Col style={{ width: '100%' }}>

                    <div style={{
                        marginTop: '-20rem',
                        textAlign: 'center',
                        marginBottom: '2rem',
                        verticalAlign: 'middle',
                        fontFamily: 'Poppins',
                        fontSize: '1.3rem',
                        color: '#969696',
                    }}>
                        Somos um Sistema Alternativo de Saúde, possuímos parceria com as melhores clínicas, laboratórios e hospitais da região. Somos norteados pelo nosso amor pela saúde e por compartilhar desse amor com você, muito além do que vender benefícios, o que nos move diariamente é poder fazer cada vez mais para quem deseja cuidar da saúde de maneira rápida e com confiança.
                        <br />
                        <br />
                        Nosso portfólio conta com mais de 100 profissionais parceiros que são os melhores no seu ramo de atuação e que oferecerão atendimento de qualidade para você e sua família, além de condições de pagamento facilitadas.
                        <br />
                        <br />
                        Nosso objetivo é levar a você uma solução completa para que você encontre tudo o que  precisa de forma simples e prática. Possuímos uma rede de clínicas próprias, que facilitam aos nossos usuários realizar Consultas Médicas, Procedimentos Odontológicos, Exames Laboratoriais e Exames de Imagem, em único lugar! Vem ser MIX você também!
                    </div>

                </Col>
            </Row>
            <br />
        </>
    )
}

export default Sobre