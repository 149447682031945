/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Filter, Trash2, Download, FilePlus } from "react-feather";
import { toast } from "react-toastify";
import {
  Paginador, SaveDocumento, RemoveDocumento
} from "../../../service/credenciadosPdf";

import { Card, CardBody, Row, Col, Button, Label, Input, Badge } from "reactstrap";
import SweetAlert2 from 'react-sweetalert2';
import ModalDocumentos from "./modal";

const DocumentosAdm = () => {

  const [swalProps, setSwalProps] = useState({});
  const [pagina, setPagina] = useState(1);
  const [tipoModal, setTipoModal] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [fileName, setFileName] = useState('');

  const [dataForm, setDataForm] = useState([{
    descricao: "",
    pdf: undefined
  }]);

  const [filter, setFilter] = useState([
    {
      pagina: pagina,
      limite: 10,
      descricao: null,
      ciclopay: null
    },
  ]);

  const [dados, setDados] = useState([
    {
      descricao: "",
      pdf: undefined
    },
  ]);

  const [spinner, setSpinner] = useState(false);
  const [tooltipId, setTooltipId] = useState(null);

  const showTooltip = (id) => {
      setTooltipId(id);
  };

  const hideTooltip = () => {
      setTooltipId(null);
  };
  
  const handleOpenModal = (tipo, dados) => {

    setDataForm({
      descricao: "",
      pdf: undefined
    });

    setTipoModal(tipo);
    setOpenModal(true);
  };

  function TrashClick(id) {
    setSwalProps({
      show: true,
      title: 'Deseja remover o Documento?',
      text: "Não será possível reverter esta ação!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, remover!',
      cancelButtonText: 'Não, cancelar!',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      focusCancel: true,
      focusConfirm: false,

      preConfirm: () => {
        RemoverDocumento(id);
        setSwalProps({ show: false });
      }

    });
  }

  const RemoverDocumento = (id) => {

    RemoveDocumento(id).then((result) => {
      toast.success("Documento removido com sucesso!");
      getDocumentos();
    }
    ).catch((error) => {
      console.log(error);
      toast.error("Erro ao remover o Documento!");
    }
    );
  };

  const getDocumentos = () => {
    Paginador(filter).then((result) => {
      setDados(result.data.content);
    }).catch((error) => {
      console.log(error);
      toast.error("Erro ao carregar os dados!");
    });
  };

  useEffect(() => {
    getDocumentos();
  }, []);

  const onSubmit = () => {

    if (!dataForm.descricao) {
      toast.error("Informe a descrição do Documento!");
      return;
    }

    if (!dataForm.pdf) {
      toast.error("Anexe o PDF do Documento!");
      return;
    }

    setSpinner(true);

    SaveDocumento(dataForm).then((result) => {
      if (result.response && result.response.status !== 201) {
        toast.error("Erro ao cadastrar o Documento!");
        setSpinner(false);
        return;
      }

      setSpinner(false);

      if (dataForm.id) {
        toast.success("Documento ajustado com sucesso!");
      } else {
        toast.success("Documento cadastrado com sucesso!");
      }

      setOpenModal(false);
      getDocumentos();
    }).catch((error) => {
      toast.error("Erro ao cadastrar o Documento!");
    });
  };

  const columns = [
    {
      name: "Descrição",
      selector: "descricao",
      sortable: true,
      cell: (row) => (
        <>
          {row.descricao}
        </>
      ),
    },
    {
      name: "Ações",
      selector: "acoes",
      sortable: true,
      right: true,
      cell: (row) => (
        <>
          <div onMouseOver={() => showTooltip('baixar-' + row.id)} onMouseLeave={() => hideTooltip()}
            style={{ position: 'relative', display: 'inline-block', cursor: 'pointer', }
            }>
            <a href={row.pdf} download="Example-PDF-document" target="_blank" rel="noreferrer" >
              <Download style={{ cursor: "pointer", color: "black"}} size={18} onClick={() => handleOpenModal("Editar", row.id)} />
            </a>
            {tooltipId === 'baixar-' + row.id && (
              <div className="tooltip-adm">Baixar</div>
            )}
          </div>
          &nbsp;&nbsp;
          <div onMouseOver={() => showTooltip('remover-' + row.id)} onMouseLeave={() => hideTooltip()}
            style={{ position: 'relative', display: 'inline-block', cursor: 'pointer', }
            }>
            <Trash2 style={{ cursor: "pointer" }} onClick={() => TrashClick(row.id)} size={18}></Trash2>
            {tooltipId === 'remover-' + row.id && (
              <div className="tooltip-adm">Remover</div>
            )}
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <ModalDocumentos
        tipoModal={tipoModal}
        openModal={openModal}
        setOpenModal={setOpenModal}
        dataForm={dataForm}
        setDataForm={setDataForm}
        onSubmit={onSubmit}
        spinner={spinner}
        dados={dados}
        fileName={fileName}
        setFileName={setFileName}
      />
      <SweetAlert2 {...swalProps} didClose={
        () => setSwalProps({ show: false })
      } />
      <br />
      <Row>
        <Card>
          <CardBody>
            <Row>
              <Col sm="2">
                <h4>Documentos</h4>
              </Col>
              <Col
                sm="10"
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  alignItems: "right",
                }}
              >
                <Button
                  className="buttonSideFilter"
                  onClick={() => setOpenFilter(!openFilter)}
                >
                  <Filter size={18} />
                </Button>
                &nbsp;
                <Button
                  size="sm"
                  className="buttonSide"
                  onClick={() => handleOpenModal("Criar")}
                >
                  <FilePlus size={18} />
                  &nbsp; Novo Documento
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
      <br />
      <div
        style={{
          display: openFilter ? "block" : "none",
          transitionTimingFunction: "ease-in-out",
          transitionDuration: "2s",
          transform: "translateY(-10px)",
        }}
      >
        <Row>
          <Card>
            <CardBody>
              <Row>
                <Col sm="1">
                  <h5>Filtros</h5>
                </Col>
                <Col sm="11">
                  <Row>
                    <Col sm="3">
                      <Label>Documento</Label>
                      <Input
                        type="text"
                        value={filter.descricao}
                        onChange={(e) => setFilter({ ...filter, descricao: e.target.value })}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm="12">
                      <Button
                        size="sm"
                        onClick={() => getDocumentos()}
                        className="buttonSide"
                        style={{ float: "right", width: "100px" }}
                      >
                        Filtrar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
      </div>

      <Row>
        <Card>
          <CardBody>
            <Row>
              <DataTable
                columns={columns}
                data={dados}
                pagination
                highlightOnHover
                responsive
                striped
                noDataComponent="Nenhum registro encontrado!"
              />
            </Row>
          </CardBody>
        </Card>
      </Row>
    </>
  );
};

export default DocumentosAdm;