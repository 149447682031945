import React, { useEffect } from 'react'
import { Card, Row, Col, Button } from 'reactstrap';
import { Paginador } from '../../../service/blog/index'
import { Search } from 'react-feather';
import { useNavigate } from 'react-router-dom';

const BlogList = () => {
    
    const [result, setResult] = React.useState([])
    const navigate = useNavigate()

    const getBlog = async () => {
        const body = {
            pagina: 1,
	        limite: 10,
	        descricao: null
        }

        Paginador(body)
        .then((res) => {
            console.log(res.data.data)
            setResult(res.data.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        getBlog()
    }, [])

    const viewMidia = (item) => {
        
        if(item !== null || item !== undefined){
            if(item.indexOf(".mp4") > -1){
                return (
                    <video width="100%" height="100%" controls src={item} />
                )
            }else{
                return (
                    <div style={{
                        width: "98%",
                        height: "240px",
                        backgroundImage: `url(${item})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}></div>
                )
            }
        }else{
            return (
                "Sem midia"
            )
        }
    }
    
    return (
    <>
    <Row>
        <div
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            marginTop: "20px",
            padding: "50px",


        }}
        >
        {result.map((item, index) => (
            <>
            <Card style={{
                width: "80%",
                height: "300px",
                padding: "20px",
                boxShadow: "0 0 10px rgba(15,15,15,0.2)",
                borderColor: "transparent",
            }} key={index} className="col-md-4">
                <Row>
                    <Col md="5">
                        <Row>
                            {viewMidia(item.midia)}
                        </Row>
                    </Col>
                    <Col md="7">
                        <Row>
                            <h3>{item.titulo}</h3>
                        </Row>
                        <Row>
                            <p>{item.conteudo.length > 350 ? item.conteudo.substring(0, 350) + "..." : item.conteudo}</p>
                        </Row>
                        <div style={{
                width: "80%",
                height: "300px"

            }}>
                <Button style={{color: "#fff"}} color='info' onClick={() => {
                    navigate(`/page?id=${item.id}`)
                }} >
                   <Search color='#fff'></Search> Leia mais 
                </Button>
            </div>
                    </Col>
                </Row>
                
            </Card>
            <br />
            </>    
        ))}
        </div>
        
    </Row>
    </>
    )
    }

    export default BlogList