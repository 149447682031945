import React from 'react';
import HomeStructure from './pages/home';
import StructureADM from './pages/admin/admin';

const App = () => {

const userdata = JSON.parse(localStorage.getItem('user'));

    if (userdata) {
        if(userdata.role !== 'admin'){
            return (
                <HomeStructure />
            );
        } else {
            return (
                <StructureADM />
            );
        }
    } else {
        return (
            <HomeStructure />
        );
    }
}

export default App;