import React, { useState } from "react";
import bgImage from "../../../assets/images/franqueado.png";
import bgVideo from "../../../assets/video/1959137982.mp4";
import lupa from "../../../assets/images/bg/lupa.png";
import { Input, Label, Button, Row, Col } from "reactstrap";
import { Search } from "react-feather";

const Franqueado = () => {
  const [formNome, setFormNome] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formEstado, setFormEstado] = useState("");
  const [formCidade, setFormCidade] = useState("");
  const [formTelefone, setFormTelefone] = useState("");
  const [fomrModelo, setFormModelo] = useState("");

  return (
    <>
      <div style={{ width: "100%", display: "flex" }}>
        <div
          className="especialidade-hero-web"
          style={{
            flexDirection: "column",
            width: "50%",
            height: "100vh",
            backgroundColor: "#1A4066",
            alignItems: "flex-start",
            justifyContent: "center",
            color: "white",
            fontSize: "200%",
            padding: "5rem 6rem 3rem 8rem",
            fontFamily: "Poppins",
            lineHeight: "16px",
          }}
        >
          <div className="especialidade-hero-web-image">
            <span className="texto-grande">
              Invista
              <br />
              no Futuro
              <br />
              da Saúde
            </span>
            <br />
            <span className="texto-pequeno">
              Realize o sonho de empreender na área da
              <br />
              Saúde. Descubra a excelência de nossa rede
              <br />
              de clínica médica e odontológica e plano de
              <br />
              benefícios.{" "}
            </span>
          </div>
        </div>
        <div
          className="especialidade-hero-web-image"
          style={{
            display: "inline-block",
            width: "50%",
            height: "100vh",
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
      <br />

      <div className="especialidade-hero-app" style={{ width: "100%" }}>
        <div
          style={{
            display: "inline-block",
            width: "100%",
            height: "22vh",
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div
          style={{
            marginTop: "-6px",
            flexDirection: "column",
            width: "100%",
            height: "17vh",
            backgroundColor: "#1A4066",
            alignItems: "flex-start",
            justifyContent: "center",
            color: "white",
            fontSize: "23px",
            padding: "3rem 1rem 0rem 1rem",
            fontFamily: "Poppins",
          }}
        >
          <div
            style={{
              textAlign: "center",
              height: "70vh",
              verticalAlign: "middle",
            }}
          >
            São mais de{" "}
            <span
              style={{
                fontFamily: "Poppins-Bold",
              }}
            >
              40
            </span>{" "}
            especialidades ao seu alcance!
          </div>
        </div>
      </div>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "0 6%",
        }}
      >
        <div
          style={{
            backgroundColor: "#1CB1E2",
            width: "60%",
            height: "560px",
            borderRadius: "25px",
            zIndex: "9999",
            marginTop: "-90px",
            padding: "30px",
            marginBottom: "20px",
          }}
        >
          <div className="texto_sup_video">
            Invista em um modelo de negócio que apresenta crescimento contínuo
          </div>
          <video width="100%" style={{ borderRadius: "15px" }} controls>
            <source src={bgVideo} type="video/mp4" />
            Seu navegador não suporta a reprodução de vídeos.
          </video>
          <br />
          <div className="texto_inf_video">
            CONHEÇA NOSSOS MODELOS DE NEGÓCIO
          </div>
        </div>
      </div>
      <span className="texto-franqueado-2">Unidade Licenciada</span>
      <span className="texto-franqueado-3">
        Abertura de Clínica e Implantação do Plano Mix da Saúde
      </span>

      <div className="tabelas-1">
        <div className="tabelas-1-centro">
          {/* CARD 1 */}
          <div className="tabelas-1-card">
            <div className="titulo-tabelas">STARTER</div>
            <span className="tabelas-texto">
              Cidade com população entre 50.000 e 70.000 habitantes
            </span>
            <table border="1">
              <tbody>
                <tr>
                  <td className="coluna-esquerda">Área</td>
                  <td>180m²</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Taxa de franquia </td>
                  <td>R$ 100.00,00</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Capital de Giro</td>
                  <td>R$ 300.000,00</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Investimento Total</td>
                  <td>A partir de R$ 720.000,00</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Royalties</td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Fundo de Marketing</td>
                  <td>2%</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Lucratividade Média</td>
                  <td>15% a 22%</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Payback Médio</td>
                  <td>36 a 48 Meses</td>
                </tr>
              </tbody>
            </table>
            <span className="tabelas-texto-2">INVESTIMENTO INICIAL</span>
            <span className="tabelas-texto-3">R$ 300.000,00</span>
          </div>
          {/* CARD 2 */}
          <div className="tabelas-1-card">
            <div className="titulo-tabelas">STANDARD</div>
            <span className="tabelas-texto">
              Cidade com população entre 70.000 e 120.000 habitantes
            </span>
            <table border="1">
              <tbody>
                <tr>
                  <td className="coluna-esquerda">Área</td>
                  <td>250m²</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Taxa de franquia </td>
                  <td>R$ 200.00,00</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Capital de Giro</td>
                  <td>R$ 500.000,00</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Investimento Total</td>
                  <td>A partir de R$ 1.150.000,00</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Royalties</td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Fundo de Marketing</td>
                  <td>2%</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Lucratividade Média</td>
                  <td>16% a 25%</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Payback Médio</td>
                  <td>36 a 48 Meses</td>
                </tr>
              </tbody>
            </table>
            <span className="tabelas-texto-2">INVESTIMENTO INICIAL</span>
            <span className="tabelas-texto-3">R$ 450.000,00</span>
          </div>
          {/* CARD 3 */}
          <div className="tabelas-1-card">
            <div className="titulo-tabelas">SILVER</div>
            <span className="tabelas-texto">
              Cidade com população acima de 120.000 habitantes
            </span>
            <table border="1">
              <tbody>
                <tr>
                  <td className="coluna-esquerda">Área</td>
                  <td>2.000m²</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Taxa de franquia </td>
                  <td>R$ 300.00,00</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Capital de Giro</td>
                  <td>R$ 300.000,00</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Investimento Total</td>
                  <td>A partir de R$ 9.300.000,00</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Royalties</td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Fundo de Marketing</td>
                  <td>2%</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Lucratividade Média</td>
                  <td>18% a 30%</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Payback Médio</td>
                  <td>54 a 72 Meses </td>
                </tr>
              </tbody>
            </table>
            <span className="tabelas-texto-2">INVESTIMENTO INICIAL</span>
            <span className="tabelas-texto-3">R$ 6.500.000,00</span>
          </div>
          {/* CARD 4 */}
          <div className="tabelas-1-card">
            <div className="titulo-tabelas">GOLD</div>
            <span className="tabelas-texto">
              Cidade com população entre 50.000 e 70.000 habitantes
            </span>
            <table border="1">
              <tbody>
                <tr>
                  <td className="coluna-esquerda">Área</td>
                  <td>180m²</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Taxa de franquia </td>
                  <td>R$ 100.00,00</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Capital de Giro</td>
                  <td>R$ 300.000,00</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Investimento Total</td>
                  <td>A partir de R$ 720.000,00</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Royalties</td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Fundo de Marketing</td>
                  <td>2%</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Lucratividade Média</td>
                  <td>15% a 22%</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Payback Médio</td>
                  <td>36 a 48 Meses</td>
                </tr>
              </tbody>
            </table>
            <span className="tabelas-texto-2">INVESTIMENTO INICIAL</span>
            <span className="tabelas-texto-3">R$ 300.000,00</span>
          </div>
        </div>
      </div>
      <span className="texto-franqueado-2">Plano Mix da Saúde</span>
      <span className="texto-franqueado-3">
        Implantação do Plano Mix da Saúde em sua clínica
      </span>

      <div className="tabelas-1">
        <div className="tabelas-1-centro">
          {/* CARD 5 */}
          <div className="tabelas-1-card">
            <div className="titulo-tabelas">STARTER</div>
            <span className="tabelas-texto">Somente Implantação do Plano</span>
            <table border="1">
              <tbody>
                <tr>
                  <td className="coluna-esquerda">Área</td>
                  <td>180m²</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Taxa de franquia </td>
                  <td>R$ 100.00,00</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Capital de Giro</td>
                  <td>R$ 100.000,00</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Investimento Total</td>
                  <td>A partir de R$ 200.000,00</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Royalties</td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Fundo de Marketing</td>
                  <td>2%</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Lucratividade Média</td>
                  <td>25% a 30%</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Payback Médio</td>
                  <td>18 a 24 Meses</td>
                </tr>
              </tbody>
            </table>
            <span className="tabelas-texto-2">INVESTIMENTO INICIAL</span>
            <span className="tabelas-texto-3">R$ 200.000,00</span>
          </div>
          {/* CARD 6 */}
          <div className="tabelas-1-card">
            <div className="titulo-tabelas">STANDARD</div>
            <span className="tabelas-texto">
              Implantação do Plano + Consultoria
            </span>
            <table border="1">
              <tbody>
                <tr>
                  <td className="coluna-esquerda">Área</td>
                  <td>250m²</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Taxa de franquia </td>
                  <td>R$ 150.00,00</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Capital de Giro</td>
                  <td>R$ 140.000,00</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Investimento Total</td>
                  <td>A partir de R$ 290.000,00</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Royalties</td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Fundo de Marketing</td>
                  <td>2%</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Lucratividade Média</td>
                  <td>25% a 30%</td>
                </tr>
                <tr>
                  <td className="coluna-esquerda">Payback Médio</td>
                  <td>36 a 48 Meses</td>
                </tr>
              </tbody>
            </table>
            <span className="tabelas-texto-2">INVESTIMENTO INICIAL</span>
            <span className="tabelas-texto-3">R$ 290.000,00</span>
          </div>
        </div>
      </div>

      <div
        className="especialidade-hero-web"
        style={{ width: "100%", display: "flex" }}
      >
        <div
          style={{
            flexDirection: "column",
            width: "100%",
            height: "85vh",
            backgroundColor: "#1CB1E2",
            alignItems: "flex-start",
            justifyContent: "center",
            color: "white",
            fontSize: "14px",
            fontFamily: "Poppins",
            marginTop: "20px",
          }}
        >
          <div className="mail-franqueado">
            <span className="mail-franqueado-txt-1">
              Seja um franqueado Mix Da Saúde
            </span>
            <span className="mail-franqueado-txt-2">
              Preencha os dados e faça parte dessa evolução na área da Saúde
            </span>
          </div>
          <div className="mail-franqueado-card-container">
            <div className="mail-franqueado-card">
              <div className="mail-franqueado-form">
                <Input
                  className="input-form"
                  placeholder="Nome Completo"
                  type="text"
                  value={formNome}
                  onChange={(e) => setFormNome(e.target.value)}
                />

                <Input
                  className="input-form"
                  placeholder="E-mail"
                  type="text"
                  value={formEmail}
                  onChange={(e) => setFormEmail(e.target.value)}
                />

                <Input
                  className="input-form"
                  placeholder="Cidade"
                  type="text"
                  value={formCidade}
                  onChange={(e) => setFormCidade(e.target.value)}
                />

                <Input
                  className="input-form"
                  placeholder="Estado"
                  type="text"
                  value={formEstado}
                  onChange={(e) => setFormEstado(e.target.value)}
                />

                <Input
                  className="input-form"
                  placeholder="Telefone"
                  type="text"
                  value={formTelefone}
                  onChange={(e) => setFormTelefone(e.target.value)}
                />

                <Input
                  className="input-form"
                  placeholder="Modelo escolhido"
                  type="select"
                  value={fomrModelo}
                  onChange={(e) => setFormModelo(e.target.value)}
                >
                  <option>Selecione um Modelo</option>
                  <option>Unidade Licenciada - STARTER</option>
                  <option>Unidade Licenciada - STANDARD</option>
                  <option>Unidade Licenciada - SILVER</option>
                  <option>Unidade Licenciada - GOLD</option>
                  <option>Plano Mix da Saúde - STARTER</option>
                  <option>Plano Mix da Saúde - STANDARD</option>
                </Input>
              </div>
              <Button className="button-form">Quero ser franqueado</Button>
            </div>
          </div>
        </div>
      </div>
      <div
      className="container-contato-franqueado"
        
      >
        <div className="text-contato-franqueado">
          <h1>Tire suas dúvidas</h1>
          <span>
            Antes de tomar uma decisão tão crucial, é essencial contar com todas
            as informações necessárias. Estamos aqui para fornecer os
            esclarecimentos que você precisa, garantindo que esteja totalmente
            informado antes de dar o próximo passo.
          </span>
          <br></br>
          <br></br>
          <h5>Silvio Eidt Jr.</h5>
          <span>66 99968-1305</span>
          <br></br>
          <span>diretoria@mixdasaude.com.br</span>
        </div>
      </div>
    </>
  );
};

export default Franqueado;
