import React, { useState } from "react";
import logo from "../../assets/images/logos/logo_header.png";
import iconface from "../../assets/images/icons/iconface.png";
import iconinsta from "../../assets/images/icons/iconinsta.png";
import { Link, useNavigate } from "react-router-dom";
import { Button} from "react-bootstrap";
import { Input } from "reactstrap";
import { logar } from "../../service/login";
import { toast } from "react-toastify";
import { Eye, EyeOff, LogOut, Menu } from "react-feather";

const Header = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [showLogin, setShowLogin] = React.useState(false);
  const [showPSW, setShowPSW] = React.useState(false);

  const navigate = useNavigate();

  const handleLogin = () => {
    const body = {
      email: login,
      senha: password,
    };
    logar(body).then((response) => {
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user_data));
        if (response.data.user_data.role === "admin") {
          navigate("/adm/dashboardadm");
          setShowLogin(false);
          window.location.reload();
        } else if (response.data.user_data.role === "cliente") {
          console.log("cliente tipo", response.data.user_data.role);
          navigate("/cliente/dashboardcliente");
          setShowLogin(false);
          window.location.reload();
        }
      } else {
        toast.error("Usuário ou senha inválidos");
      }
    });
  };

  return (
    <>
      
      <div className="MenuLarge">
        <div
          className="subheader"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginRight: "7.55%",
            marginBottom: "-18px",
          }}
        >
          <a
            style={{ textDecoration: "none", marginTop: "-4px" }}
            href="https://api.whatsapp.com/send?phone=5566984383302&text="
            target="_blank"
            rel="noreferrer"
          >  
          <span
            style={{
              color: "#969696",
              fontSize: "12px",
              margin: "10px",
              fontFamily: "Poppins",
            }}
          >
            Central de Relacionamento
          </span>
          <span
            style={{
              color: "#969696",
              fontSize: "12px",
              margin: "10px",
              fontFamily: "Poppins-Bold",
            }}
          >
            0800 482 0800
          </span>        
          </a>
          
          &nbsp;&nbsp;
          <span
            onClick={() => navigate("/franqueado")}
            style={{
              color: "#969696",
              fontSize: "12px",
              margin: "10px",
              fontFamily: "Poppins",
              cursor: "pointer",
            }}
          >
            Quero Ser Franqueado
          </span>
          &nbsp;&nbsp;
          <span
            onClick={() => navigate("/seja-credenciado")}
            style={{
              color: "#969696",
              fontSize: "12px",
              margin: "10px",
              fontFamily: "Poppins",
              cursor: "pointer",
            }}
          >
            Quero Ser Credenciado
          </span>
          &nbsp;&nbsp;
          <span
            onClick={() => navigate("/assinatura")}
            // onClick={() => {
            //   toast.info("Em manutenção -  entre em contato diretamente com nossa Central de Relacionamento");
            // }}
            style={{
              color: "#969696",
              fontSize: "12px",
              margin: "10px",
              fontFamily: "Poppins-Bold",
              cursor: "pointer",
            }}
          >
            Contratar Online
          </span>
            <a href="https://www.facebook.com/mixdasaude" target="_blank" rel="noreferrer">
              <img src={iconface} alt="facebook" style={{ width: "20px", height: "20px" }} />
            </a>
            <a href="https://www.instagram.com/mixdasaude/" target="_blank" rel="noreferrer">
              <img src={iconinsta} alt="instagram" style={{ width: "20px", height: "20px", marginLeft: "10px" }} />
            </a>
        </div>
        <hr style={{ borderTop: "0.1px solid #969696", width: "100%" }}></hr>
        
        <div className="menu">
          <div className="buttons">
            <img onClick={() => {
              navigate("/")
              window.scrollTo(0, 0);
            }} src={logo} alt="Mix da Saúde" width={200} height={55} className="logo-image" />
            <span className="space-menu"></span>
            <Link to="/especialidades" style={{ textDecoration: "none" }}>
              <div className="buttonLink">Especialidades</div>
            </Link>

            <Link to="/credenciados" style={{ textDecoration: "none" }}>

            <div className="buttonLink">Redes Credenciadas</div>
            </Link>
            <Link to="/sobre" style={{ textDecoration: "none" }}>
              <div className="buttonLink">Sobre nós</div>
            </Link>
            <Link to="/blog" style={{ textDecoration: "none" }}>
              <div className="buttonLink">Blog</div>
            </Link>

            <div
              style={{ display: localStorage.getItem("token") ? "none" : "" }}
              onClick={() => setShowLogin(!showLogin)}
              className="buttonLink"
            >
              <Button
                style={{
                  backgroundColor: "#1CB1E2",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  marginTop: "-4px",
                }}
              >
                Área do Cliente
              </Button>
            </div>
            <div
              style={{ display: localStorage.getItem("token") ? "" : "none" }}
              onClick={() => {
                navigate("/");
                localStorage.clear();
                window.location.reload();
              }}
              className="buttonLink"
            >
              Sair <LogOut style={{ marginTop: "-3px" }} size={16}></LogOut>
            </div>
          </div>
        </div>
      </div>
      <div
        className="Login"
        style={{
          display: showLogin ? "" : "none",
          
        }}
      >
        <Input
          type="text"
          placeholder="E-mail"
          className="LoginInput"
          style={{ width: "250px", margin: "10px" }}
          value={login}
          onChange={(e) => setLogin(e.target.value)}
        />
        <Input
          type={showPSW ? "text" : "password"}
          placeholder="Senha"
          className="LoginInput"
          style={{ width: "250px", margin: "10px" }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {showPSW ? (
          <Eye
            onClick={() => setShowPSW(!showPSW)}
            style={{ margin: "10px" }}
            color="#fff"
            size={20}
          ></Eye>
        ) : (
          <EyeOff
            onClick={() => setShowPSW(!showPSW)}
            style={{ margin: "10px" }}
            color="#fff"
            size={20}
          ></EyeOff>
        )}
        <Button
          onClick={() => handleLogin()}
          style={{ margin: "10px" }}
          block
          className="LoginButton"
        >
          Entrar
        </Button>
        <span style={{ color: "#fff", fontSize: "14px", margin: "10px" }}>
          |
        </span>
        <span
          onClick={() => {
            navigate("/senha");
            setShowLogin(false);
          }}
          style={{
            color: "#fff",
            fontSize: "14px",
            margin: "10px",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          recuperar senha{" "}
        </span>
      </div>
    </>
  );
};

export default Header;
