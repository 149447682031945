/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useCallback } from "react";
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Progress
} from "reactstrap";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDropzone } from "react-dropzone";

import { Upload } from "@aws-sdk/lib-storage";

import { GetBanner } from '../../../../service/banner';

import "react-datepicker/dist/react-datepicker.css";

const ModalUsuarios = ({
  tipoModal,
  openModal,
  setOpenModal,
  dados,
  setDados,
  onSubmit,
}) => {

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve(
          reader.result
            .toString()

        );
      reader.onerror = (error) => reject(error);
    });

  const verificaDataDe = (data) => {
    // data de deve ser menor que data ate
    if (data !== null) {
      const dataDe = new Date(data).getTime();
      const dataAte = new Date(dados.dataAte).getTime();
      if (dataAte && dataDe > dataAte) {
        toast.error("Data de deve ser menor que data até");
        setDados({ ...dados, dataDe: dataAte });
      } else {
        setDados({ ...dados, dataDe: null });
      }
    }
  };

  const verificaDataAte = (data) => {
    if (data !== null) {
      const dataDe = new Date(dados.dataDe).getTime();
      const dataAte = new Date(data).getTime();
      if (dataDe && dataDe > dataAte) {
        toast.error("Data até deve ser maior que data de");
        setDados({ ...dados, dataAte: dataDe });
      } else {
        setDados({ ...dados, dataAte: null });
      }
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = async () => {
        const fileBase64 = await fileToBase64(file);
        setDados({ ...dados, imagem: fileBase64 });

      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    console.log(dados)
    if (dados && dados.id && tipoModal === "Editar") {
      console.log("CHAMANDO API NO GET")
      getBanner(dados.id);
    }
  }, []);

  const getBanner = async (id) => {
    const response = await GetBanner(id);
    if (response.status === 200 || response.status === 201) {
      const dados = response.data.data;
      setDados({
        ...dados,
        dataDe: dados.dataDe ? dados.dataDe.split("T")[0] : null,
        dataAte: dados.dataAte ? dados.dataAte.split("T")[0] : null,
      });
    } else {
      toast.error("Erro ao buscar banner");
    }
  }

  return (
    <>
      <Modal
        isOpen={openModal}
        style={{ width: "800px", minWidth: "800px" }}
        className="modal-dialog-centered"
      >
        <ModalHeader>{tipoModal} Banner</ModalHeader>
        <ModalBody style={{ marginLeft: "15px", marginRight: "15px" }}>

          <Col sm="12">
            {(tipoModal === "Criar") ? <>
              <Row>
                <Label>Imagem Capa</Label>
                {dados.imagem === undefined ? (
                  <div
                    {...getRootProps()}
                    style={{
                      width: "100%",
                      height: "18.7rem",
                      border: "1px solid #ccc",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <input {...getInputProps()} />
                    <p>Clique aqui ou arraste uma imagem para cá</p>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "18.7rem",
                      border: "1px solid #ccc",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      borderRadius: "10px",
                      cursor: "pointer",
                      padding: "10px",
                    }}
                  >
                    {
                      <img
                        onClick={() => setDados({ ...dados, imagem: undefined })}
                        src={dados.imagem}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    }
                  </div>
                )}

              </Row>
              <br />
            </>
              : 
              <>
                <div
                  style={{
                    width: "100%",
                    height: "18.7rem",
                    border: "1px solid #ccc",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  {
                    <img src={dados.imagem}
                      style={{ width: "100%", height: "100%", objectFit: "cover" }}
                    />
                  }
                </div>
              </>
            }
            <Row>
              <Col sm="12">
                <Label>Link</Label>
                <Input
                  type="text"
                  value={dados.link}
                  onChange={(e) =>
                    setDados({ ...dados, link: e.target.value })
                  }
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm="10">
                <Label>Título</Label>
                <Input
                  type="text"
                  value={dados.descricao}
                  onChange={(e) =>
                    setDados({ ...dados, descricao: e.target.value })
                  }
                />
              </Col>
              <Col sm="2">
                <p />
                <br />
                <Label>Ativo &nbsp;</Label>
                <Input
                  type="checkbox"
                  checked={dados.ativo}
                  onChange={(e) =>
                    setDados({ ...dados, ativo: e.target.checked })
                  }
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm="6">
                <Label>Data de</Label>
                <Input
                  type="date"
                  value={dados.dataDe}
                  onChange={(e) =>
                    verificaDataDe(e.target.value)
                  }
                />
              </Col>
              <Col sm="6">
                <Label>Data até</Label>
                <Input
                  type="date"
                  value={dados.dataAte}
                  onChange={(e) =>
                    verificaDataAte(e.target.value)
                  }
                />
              </Col>
            </Row>
          </Col>

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => onSubmit()}>
            Salvar
          </Button>
          <Button color="secondary" onClick={() => setOpenModal(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalUsuarios;
