import api from "../api";


export const Paginador = async (body) => {
  return api
    .post(`banner/paginate`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const PaginadorDash = async (body) => {
  return api
    .post(`banner/paginateDash`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const SaveBanner = async (body) => {

  return api
    .post(`banner/save`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const UpdateBanner = async (body) => {
  return api
    .put(`banner/update`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const GetBanner = async (id) => {
  return api
    .get(`banner/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const RemoveBanner = async (id) => {

  return api
    .delete(`banner/remove/${id}`, {

      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};




