/* eslint-disable jsx-a11y/alt-text */

import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Col, Row, Label, Input, Spinner } from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { useState, useCallback } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDropzone } from "react-dropzone";

const ModalDocumentos = (
    {
        tipoModal,
        openModal,
        setOpenModal,
        onSubmit,
        dataForm,
        setDataForm,
        spinner,
        fileName,
        setFileName
    }
) => {

    const fileToBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () =>
                resolve(
                    reader.result
                        .toString()

                );
            reader.onerror = (error) => reject(error);
    });

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onload = async () => {
                setFileName(file.name);
                const fileBase64 = await fileToBase64(file);
                setDataForm({ descricao: dataForm.descricao, pdf: fileBase64 });
            };
            reader.readAsArrayBuffer(file);
        });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <>
            <Modal isOpen={spinner} className="modal-dialog-centered">
                <ModalBody>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "20px",
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: '#1CB1E2'
                        }}
                    >
                        <Spinner animation="border" variant="primary" />&nbsp; &nbsp;  Aguarde...
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={openModal} style={{ width: '600px', minWidth: '600px' }} className='modal-dialog-centered'>
                <ModalHeader>
                    {tipoModal} Documento
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm='12'>
                            <Label>Descrição</Label>
                            <Input
                                type='text'
                                value={dataForm.descricao}
                                onChange={(e) => setDataForm({ ...dataForm, descricao: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Label>Documento em PDF</Label>
                        {dataForm.pdf === undefined ? (
                            <div
                                {...getRootProps()}
                                style={{
                                    width: "100%",
                                    height: "18.7rem",
                                    border: "1px solid #ccc",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                }}
                            >
                                <input {...getInputProps()} />
                                <p>Clique aqui ou arraste um documento para cá</p>
                            </div>
                        ) : (
                            <div
                                style={{
                                    padding: "10px",
                                }}
                            >
                                {
                                    <Input
                                        type='text'
                                        value={fileName}
                                        onClick={(e) => setDataForm({ ...dataForm, pdf: undefined })}>  
                                    </Input>
                                }
                            </div>
                        )}
                    </Row>
                    <br />
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={() => onSubmit()}>Salvar</Button>
                    <Button color='secondary' onClick={() => setOpenModal(false)}>Cancelar</Button>
                </ModalFooter>
            </Modal >
        </>
    )
}

export default ModalDocumentos