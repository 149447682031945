import api from "../api";

export const FormSeguro = async (body) => {
  return api
    .post(`form/formSeguroVida`, body, {
      headers: {
        "Content-Type": "application/json"
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const FormCredenciado = async (body) => { 
  return api
    .post(`form/formCredenciado`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};