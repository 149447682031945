import Franqueado from "../../pages/home/franqueado";

const FranqueadoPage = () => {
    return (
        <>
        <Franqueado />
        </>
    );
    
}


export default FranqueadoPage;