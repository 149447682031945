import api from "../api";

export const Paginador = async (body) => {
  return api
    .post(`especialidade/paginate`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const SaveEspecialidade = async (body) => {
  return api
    .post(`especialidade/save`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const UpdateEspecialidade = async (body) => {
  return api
    .put(`especialidade/update`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const GetEspecialidade = async (credenciado) => {
  return api
    .get(`especialidade/${credenciado}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const RemoveEspecialidade = async (id) => {
  return api
    .delete(`especialidade/remove/${id}`, {

      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const TipoPaginador = async (body) => {
  return api
    .post(`tipocredenciado/paginate`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
  };

export const SaveTipoEspecialidade = async (body) => {
  return api
    .post(`tipocredenciado/save`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const UpdateTipoEspecialidade = async (body) => {
  return api
    .put(`tipoespecialidade/update`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const GetTipoEspecialidade = async (credenciado) => {
  return api
    .get(`tipocredenciado/${credenciado}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const RemoveTipoEspecialidade = async (id) => {
  return api
    .delete(`tipoespecialidade/remove/${id}`, {

      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};