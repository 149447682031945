import { Outlet } from 'react-router-dom'; 
import Header from '../../components/header';
import HeaderMobile from '../../components/headermobile';
import Footer from '../../components/footer';
import '../../App.css';
import "../../AppMobile.css";
import { ToastContainer } from 'react-toastify';
import { useEffect } from 'react';

function HomeStructure() {

  useEffect(() => {
    console.log("USE EFFECT STRUCTURE ADM");
    const token = localStorage.getItem('token');
    // if url is not / and token is null, redirect to /
    if(window.location.pathname !== '/assinatura' && window.location.pathname !== '/' && token === null){
      window.location.href = '/';
    }
    
  }
  ,[]);

  return (
    <>
    <div className="AppBig" style={{zIndex: 9999}}>
    <header>
      <Header />
    </header>
    </div>
    <div className="AppSmall" style={{
      position: 'relative',
      zIndex: 9999}}>
    <header>
      <HeaderMobile />
    </header>
    </div>
    <div style={{
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      zIndex: 1
    }}>
    <Outlet />
    </div>    
    <footer>
      <Footer />
    </footer>
    <ToastContainer />
    
    </>
    



  );
}

export default HomeStructure;
