import { Button, Card, Col, Row } from "react-bootstrap";
import Carrousel from "../../../components/carrousel";
import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import imageBlock1 from "../../../assets/images/icons/h1.png";
import imageBlock2 from "../../../assets/images/icons/h2.png";
import imageBlock3 from "../../../assets/images/icons/h3.png";
import imageBlock4 from "../../../assets/images/icons/h4.png";
import imageBlock5 from "../../../assets/images/icons/h5.png";
import imageBlock6 from "../../../assets/images/icons/h6.png";
import imageCredenciadas1 from "../../../assets/images/icons/c1.png";
import imageCredenciadas2 from "../../../assets/images/icons/c2.png";
import imageCredenciadas3 from "../../../assets/images/icons/c3.png";
import imageCredenciadas4 from "../../../assets/images/icons/c4.png";
import imageCredenciadas5 from "../../../assets/images/icons/c5.png";
import imageCredenciadas6 from "../../../assets/images/icons/c6.png";
import emprlogo1 from "../../../assets/images/icons/emp001.png";
import emprlogo2 from "../../../assets/images/icons/emp002.png";
import emprlogo3 from "../../../assets/images/icons/emp003.png";
import emprlogo4 from "../../../assets/images/icons/emp004.png";
import emprlogo5 from "../../../assets/images/icons/emp005.png";
import emprlogo6 from "../../../assets/images/icons/emp006.png";
import emprlogo7 from "../../../assets/images/icons/emp007.png";
import emprlogo8 from "../../../assets/images/icons/emp008.png";
import emprlogo9 from "../../../assets/images/icons/emp009.png";
import handHelt from "../../../assets/images/handhelt.png";
import assinatura from "../../../assets/images/mocacard.png";
import imageMapa from "../../../assets/images/mapa.png";
import { Input, Label } from "reactstrap";
import { toast } from 'react-toastify';
import { FormSeguro } from '../../../service/form';
import { Spinner } from "reactstrap";

const HomePage = () => {
  const [formTelefone, setFormTelefone] = useState('');
  const [formEmpresa, setFormEmpresa] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formResponsavel, setFormResponsavel] = useState('');
  const [spinner, setSpinner] = useState(false);

  const listVantagens = [
    {
      id: 1,
      title: "PLANO SEM CARÊNCIA",
      description: "Plano com agendamento de consultas e exames na hora.",
      image: imageBlock1,
    },
    {
      id: 2,
      title: "REDE DE CLÍNICA PRÓPRIA",
      description:
        "Possuímos a própria rede de Clínica Médica Franquiada no Mato Grosso, facilitando assim seu acesso a saúde. ",
      image: imageBlock2,
    },
    {
      id: 3,
      title: "AGENDA MÉDICA PRÓXIMA",
      description:
        "Não existe nada pior que precisar de um médico e ter agenda para 90 dias.",
      image: imageBlock3,
    },
    {
      id: 4,
      title: "DESCONTOS DE ATÉ 80%",
      description:
        "Na MIX, você economiza, tem acesso a valores acessíveis em consultas em exames",
      image: imageBlock4,
    },
    {
      id: 5,
      title: "SEM BUROCRACIA",
      description:
        "Não há necessidade de aprovação para realizar consultas, bastar ter o plano MIX para ter acesso aos benefícios",
      image: imageBlock5,
    },
    {
      id: 6,
      title: "+ DE 40 ESPCIALIDADES",
      description:
        "São mais de 40 especialidades médicas, incluindo atendimento odontológico, exames de imagem e exames laboratoriais.",
      image: imageBlock6,
    },
  ];

  const listCredenciados = [
    {
      id: 1,
      title: "Médicos",
      image: imageCredenciadas1,
    },
    {
      id: 2,
      title: "Clínicas",
      image: imageCredenciadas2,
    },
    {
      id: 3,
      title: "Hospitais",
      image: imageCredenciadas3,
    },
    {
      id: 4,
      title: "Laboratórios",
      image: imageCredenciadas4,
    },
    {
      id: 5,
      title: "Farmácias",
      image: imageCredenciadas5,
    },
    {
      id: 6,
      title: "Óticas",
      image: imageCredenciadas6,
    },
  ];

  const listEmpresas = [
    {
      id: 1,
      image: emprlogo1,
    },
    {
      id: 2,
      image: emprlogo2,
    },
    {
      id: 3,
      image: emprlogo3,
    },
    {
      id: 4,
      image: emprlogo4,
    },
    {
      id: 5,
      image: emprlogo5,
    },
    {
      id: 6,
      image: emprlogo6,
    },
    {
      id: 7,
      image: emprlogo7,
    },
    {
      id: 8,
      image: emprlogo8,
    },
    {
      id: 9,
      image: emprlogo9,
    },
  ];

  const WrappListaVantagens = () => {
    return listVantagens.map((item, index) => {
      return (
        <div className="cardVantagens" key={index}>
          <img
            className="cardVantagensImage"
            src={item.image}
            alt="Vantagens"
          />
          <div className="cardVantagensText">
            <p
              className="cardVantagensTextTitle"
            >
              {item.title}
            </p>
            <p
              className="cardVantagensTextDescription"
            >{item.description}</p>
          </div>
        </div>
      );
    });
  };

  const WrappListaCredenciados = () => {
    return listCredenciados.map((item, index) => {
      return (
        <div className="cardCredenciados" key={index}>
          <img
            src={item.image}
            alt="Credenciados"
            className="cardCredenciadosImage"
          />
          <div className="cardCredenciadosText">
            <p

              className="cardCredenciadosTextTitle"

            >
              {item.title}
            </p>
          </div>
        </div>
      );
    });
  };

  const WrappListaEmpresas = () => {
    return listEmpresas.map((item, index) => {
      return (
        <div className="cardEmpresas" key={index}>
          <img
            src={item.image}
            alt="Empresas"
            style={{
              width: "100%",
            }}
          />
        </div>
      );
    });
  };

  const enviarFormularioSeguro = () => {

    setSpinner(true);

    const data = {
      responsavel: formResponsavel,
      empresa: formEmpresa,
      email: formEmail,
      telefone: formTelefone
    };

    if (data.responsavel.length === 0 || data.empresa.length === 0 || data.email.length === 0 || data.telefone.length === 0) {
      toast.error('Preencha todos os campos para entrar em contato');
      setSpinner(false);
      return;
    }

    FormSeguro(data).then((response) => {
      if (response.status === 200 || response.status === 201) {

        setFormEmpresa('');
        setFormTelefone('');
        setFormEmail('');
        setFormResponsavel('');

        toast.success('Contato enviado com sucesso!');
        setSpinner(false);
        return;
      }
      setSpinner(false);
      toast.error('Erro ao enviar contato!');
    }).catch((err) => {
      console.log(err);
      setSpinner(false);
    })
  }

  const navigate = useNavigate();

  return (
    <>
      <div className="carrosselhome">      
        <Carrousel />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginTop: "1rem",
        }}
      >
        <span
          className="descanso"
        >
          Nossas
        </span>
        <span
          className="descanso2"
        >
          Vantagens
        </span>
      </div>
      <div className="cardsVanatgens">
        <div style={{
          maxWidth: "80rem",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <WrappListaVantagens />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginTop: "2rem",
        }}
      >
        <span
          className="descanso"

        >
          Ampla
        </span>
        <span
          className="descanso2"

        >
          Rede de Credenciados
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div className="credenciadosmapa"

        >
          <Row>
            <Col sm="6">
              <Row>
                <Col sm="6">
                  <img
                    src={imageMapa}
                    alt="Mapa"
                    className="mapamt"
                  />
                </Col>
                <Col sm="6">
                  <div>
                    <ul
                      className="listacredenciados"
                    >
                      <li>Sinop</li>
                      <li>Sorriso</li>
                      <li>Lucas do Rio Verde</li>
                      <li>Tapurah</li>
                      <li>Nova Mutum</li>
                      <li>Campo Novo do Parecis</li>
                      <li>Tangará da Serra</li>
                      <li>Diamantino</li>
                      <li>Cuiabá</li>
                      <li>Várzea Grande</li>
                      <li>Primavera do Leste</li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col sm="6">
              <div className="cardsCredenciados" >
                <WrappListaCredenciados />
              </div>
              <Button
                onClick={() => {
                  navigate("/credenciados")
                  window.scrollTo(0, 0);
                }}
                style={{
                  marginTop: "1rem",
                  backgroundColor: "#1CB1E2",
                  color: "#fff",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "1rem",
                  width: "14rem",
                  height: "2.2rem",
                  borderRadius: "3px",
                  border: "none",
                }}
              >
                Conhecer agora
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div className="seguros">
        <img
          src={handHelt}
          alt="Seguros"
          className="handHelt"
        />
        <div
          className="backgroundSeguros"
          style={{
            width: "100%",
            height: "3rem",
            backgroundColor: "#fff",
          }}
        ></div>
        <div
          className="backgroundSeguros"

        >
          <Row>
            <Col sm="4"></Col>
            <Col sm="3">
              <div
                className="segurostext"
              >
                <p className="segurofonta">Disponibilize</p>
                <p
                  className="segurofontb"
                >
                  Seguro de Vida{" "}
                </p>
                <p className="segurofonta">
                  e benefícios{" "}
                  <span
                    className="segurofontb"
                  >
                    MIX
                  </span>
                </p>
                <p className="segurofonta">
                  para seus colaboradores!
                </p>

                <p className="segurofontc">
                  Conheça nossos planos{" "}
                </p>
                <p
                  className="segurofontc"

                >
                  empresariais.
                </p>
              </div>
            </Col>
            <Col sm="5">
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px",
                }}
              >
                <Card
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#fff",
                    borderRadius: "25px",
                    border: "none",
                  }}
                >
                  <Card.Body>
                    <div className="divCotacao">
                      <Row>
                        <Col sm="12">
                          <Label
                            className="inputNome"
                          >
                            Responsável
                          </Label>
                          <Input
                            className="inputNome"
                            value={formResponsavel}
                            onChange={(e) => setFormResponsavel(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <Label
                            className="inputEmpresa"
                          >
                            Empresa
                          </Label>
                          <Input
                            className="inputEmpresa"
                            value={formEmpresa}
                            onChange={(e) => setFormEmpresa(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <Label
                            className="labelEmail"
                          >
                            E-mail
                          </Label>
                          <Input
                            className="labelEmail"
                            type="text"
                            value={formEmail}
                            onChange={(e) => setFormEmail(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <Label
                            className="inputTelefone"
                          >
                            Telefone
                          </Label>
                          <Input
                            className="inputTelefone"
                            type="text"
                            value={formTelefone}
                            onChange={(e) => setFormTelefone(e.target.value)}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "1rem",
                      }}
                    >
                      { (spinner) ? 
                      <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "20px",
                            fontSize: "20px",
                            fontWeight: "bold",
                        }}
                      >
                        <Spinner animation="border" variant="primary" /> &nbsp; &nbsp;  Aguarde...
                      </div>
                    : 
                      <>
                        <Button className="btnCotacao" onClick={() => enviarFormularioSeguro()}>
                          Quero fazer uma cotação
                        </Button>
                      </>
                    }
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <br />
      <div className="empresas">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginTop: "1rem",
          }}
        ></div>
        <Row>
          <Col sm="6" className="empresaVsisibleLG">
            <div className="divEmpresas"
            >
              <WrappListaEmpresas />
            </div>
          </Col>
          <Col sm="6">
            <div
              className="divEmpresasText"
            >
              Conheça algumas empresas da região que já confiam na Mix da Saúde
            </div>
          </Col>
          <Col sm="6" className="empresaVsisibleSM">
            <div className="divEmpresas"
            >
              <WrappListaEmpresas />
            </div>
          </Col>
        </Row>
      </div>
      <br />
      <Row>
        <Col sm="6" className="assinaturaFoto">

          <img src={assinatura} alt="Assinatura" style={{
            width: "70rem",
            height: "100%",
            marginLeft: "-21rem",
          }} />
        </Col>
        <Col sm="6">

          <div className="assinatura"
          >
            <div className="">
              <span
                className="textAssinaturaA">
                Contrate
              </span>{" "}
              <span
                className="textAssinaturaB"
              >
                nosso Plano sem
              </span>{" "}
              <span
                className="textAssinaturaA"
              >
                sair de casa!
              </span>
              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div class="badgecircle">
                  <span class="badgenumber">1</span>
                </div>
                <span
                  className="textAssinaturaItemTitulo"
                >
                  {" "}
                  Faça sua cotação
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div class="badgeLine">
                  <hr class="rotated-hr" />
                </div>
                <span
                  className="textAssinaturaItemDescricao"
                >
                  {" "}
                  Selecione a quantidade de dependentes adultos e crianças e veja o
                  valor final de sua mensalidade.
                </span>
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div class="badgecircle">
                  <span class="badgenumber">2</span>
                </div>
                <span
                  className="textAssinaturaItemTitulo"
                >
                  {" "}
                  Cadastro
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div class="badgeLine">
                  <hr class="rotated-hr" />
                </div>
                <span
                  className="textAssinaturaItemDescricao"
                >
                  {" "}
                  Realize o seu cadastro e de seus dependentes anexando seus
                  respectivos RG ou CNH frente e verso.
                </span>
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div class="badgecircle">
                  <span class="badgenumber">3</span>
                </div>
                <span
                  className="textAssinaturaItemTitulo"
                >
                  {" "}
                  Pagamento
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div class="badgeLine"></div>
                <span
                  className="textAssinaturaItemDescricao"
                  style={{
                    marginLeft: "2rem",
                  }}
                >
                  {" "}
                  Realize o cadastro do seu cartão de crédito, para realizar o
                  pagamento recorrente.
                </span>
              </div>
            </div>
          </div>
          <br />
          <div className="btcomprarhome">
            <Button
              onClick={() => {
                navigate("/assinatura")
                window.scrollTo(0, 0);
              }}
              style={{
                marginTop: "1rem",
                backgroundColor: "#1CB1E2",
                color: "#fff",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "1.2rem",
                width: "20rem",
                height: "2.5rem",
                borderRadius: "3px",
                border: "none",
              }}
            >
              Contrate Online
            </Button>
          </div>
          <br />
          <br />
          <br />
        </Col>
      </Row>
    </>
  );
};

export default HomePage;
