import api from "../api";


export const Paginador = async (body) => {
  console.log(body);
  return api
    .post(`assinatura/paginate`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const SaveAssinatura = async (body) => {
  return api
    .post(`assinatura/save`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const PrintAssinatura = async (id) => {
  const body = {
    assinatura: id
  }
  return api
    .post(`assinatura/baixarAssinatura`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const PagamentoForcado = async (id) => {
  const body = {
    id: id
  }
  return api
    .post(`assinatura/cobrarNovamente`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const PrintExport = async (id) => {

  return api
    .get(`assinatura/exportar`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const Pagamento = async (body) => {
  return api
    .post(`assinatura/assinar`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const Cancelamento = async (body) => {
  console.log("DADOS ENVIADOS:", body);
  return api
    .post(`assinatura/cancelar`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const Ativar = async (body) => {
  console.log("DADOS ENVIADOS:", body);
  return api
    .post(`assinatura/ativar`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const TrocarCartao = async (body) => {
  return api
    .get(`assinatura/atualizarCartao/${body.assinatura_id}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
}

export const Suspender = async (body) => {
  console.log("DADOS ENVIADOS:", body);
  return api
    .post(`assinatura/suspender`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const UpdateAssinatura = async (body) => {
  return api
    .put(`assinatura/update`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const CancelarAssinatura = async (user) => {
  return api
    .delete(`assinatura/cancelar`, user, {

      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};




