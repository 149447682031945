import React, { useEffect, useState } from "react";
import whats from "../../../assets/images/icons/pgresultado/whats.png";
import insta from '../../../assets/images/icons/pgresultado/insta.png'
import face from '../../../assets/images/icons/pgresultado/face.png'
import logradouro from "../../../assets/images/icons/pgresultado/logradouro.png";
import cidadeico from "../../../assets/images/icons/pgresultado/cidade.png";
import { Button, Col, Row } from "react-bootstrap";
import { Search } from "react-feather";
import AsyncSelectCidade from "../../../components/asynselectcidade";
import AsyncSelectEstado from "../../../components/asynselectestado";
import AsyncSelectCredenciado from "../../../components/asynselectcredenciado";
import { Paginador, PaginadorPromocao } from '../../../service/credenciados/index'

const Credenciados = () => {

  const [resultado, setResultado] = useState([]);
  const [resultadoMiddle, setResultadoMiddle] = useState([]);
  const [promo, setPromo] = useState([]);
  const [cidade, setCidade] = useState([]);
  const [estado, setEstado] = useState([]);
  const [credenciado, setCredenciado] = useState([]);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [limite, setLimite] = useState(8);
  const [mostrarVerMais, setMostrarVerMais] = useState(true);

  const getPaginador = async () => {
    const body = {
      pagina: 1,
      limite: limite,
      descricao: "",
      cidade_id: cidade.value,
      estado_id: estado.value,
      especialidade_id: credenciado.value,
    };

    Paginador(body).then((response) => {
      
      if (paginaAtual < response.data.totalPages) {
        setMostrarVerMais(true);
      } else {
        setMostrarVerMais(false);
      }

      setResultado(response.data.content);
    });
  };

  const getPaginadorPromocao = async () => {
    const body = {
      page: 1,
      itensPerPage: 30,
      descricao: "",
      cidade_id: cidade.value,
      estado_id: estado.value,
      especialidade_id: credenciado.value,
      limite: 4
    }
    PaginadorPromocao(body).then((response) => {
      console.log("CONSOLE", response.data.content);
      setPromo(response.data.content)
    });
  }

  useEffect(() => {
    setPaginaAtual(1);
    getPaginadorPromocao();
    getPaginador();
  }, [cidade, estado, credenciado]);

  const CardResultado = (dados) => {

    return (
      <>
        <div
          style={{
            width: "220px",
            height: "380px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            alignItems: "left",

            backgroundColor: "#fff",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "200px",
              backgroundImage: `url(${dados.imagem})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "10px",
            }}
          ></div>
          <span
            style={{
              fontFamily: "Poppins",
              color: "#1A4066",
              fontSize: "0.8rem",
              fontWeight: "bold",
              marginTop: "0.5rem",
              marginBottom: "0.3rem",
            }}
          >
            {dados.nome}
          </span>
          <div
            style={{
              marginBottom: "0.3rem",
            }}
          >
            <img
              src={cidadeico}
              alt="cidade"
              style={{
                width: "20px",
                height: "20px",
                marginRight: "0.5rem",
              }}
            ></img>
            <span
              style={{
                fontFamily: "Poppins",
                color: "#969696",
                fontSize: "0.8rem",
                marginTop: "0.5rem",
              }}
            >
              {dados.cidade}
            </span>
          </div>
          <div
            style={{
              marginBottom: "0.3rem",
            }}
          >
            <img
              src={logradouro}
              alt="logradouro"
              style={{
                width: "20px",
                height: "20px",
                marginRight: "0.5rem",
              }}
            ></img>
            <span
              style={{
                fontFamily: "Poppins",
                color: "#969696",
                fontSize: "0.8rem",
                marginTop: "0.5rem",
              }}
            >
              {dados.endereco.length > 18 ? dados.endereco.substring(0, 18) + "..." : dados.endereco} - {dados.bairro.length > 8 ? dados.bairro.substring(0, 8) + "..." : dados.bairro}
            </span>
          </div>
          <div
            style={{
              marginBottom: "0.3rem",
            }}
          >
            <img
              src={whats}
              alt="whats"
              style={{
                width: "20px",
                height: "20px",
                marginRight: "0.5rem",
              }}
            ></img>
            <span
              style={{
                fontFamily: "Poppins",
                color: "#969696",
                fontSize: "0.8rem",
                marginTop: "0.5rem",
              }}
            >
              {dados.telefone}
            </span>
          </div>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",

            marginBottom: "0.5rem",

          }}>
            <div style={{ display: (!dados.link_instagram || dados.link_instagram.length === 0) ? "none" : "block" }}>
              <a href={dados.link_instagram} target="_blank" rel="noreferrer">
                <img src={insta} alt="insta" style={{ cursor: 'pointer', width: "20px", height: "20px", marginRight: "0.5rem" }}></img>
              </a>
            </div>
            <div style={{ display: (!dados.link_facebook || dados.link_facebook.length === 0) ? "none" : "block" }}>
              <a href={dados.link_facebook} target="_blank" rel="noreferrer">
                <img src={face} alt="face" style={{ cursor: 'pointer', width: "20px", height: "20px", marginRight: "0.5rem" }}></img>
              </a>
            </div>
            <Button
              onClick={() => {
                window.open(dados.link_maps, "_blank");
              }}
              style={{
                backgroundColor: "#1A4066",
                height: "25px",
                fontSize: "0.8rem",
                padding: "0.1rem",
                marginLeft: "auto",
              }} >&nbsp;&nbsp;<Search size={14} style={{ marginTop: "-3px" }}></Search> &nbsp;&nbsp;Ver no mapa&nbsp;&nbsp;</Button>
          </div>
        </div>
      </>
    );
  };

  const CarruselPromo = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F1F3F9",
            padding: "1rem",
          }}
        >
          {promo.map((item) => {
            return (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1rem",
                }}
              >
                <a href={item.link_maps} target="_blank" rel="noreferrer" style={{ cursor: "pointer" }}>
                  <div
                    style={{
                      width: "200px",
                      height: "200px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "1rem",
                      backgroundImage: `url(${item.imagem})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderRadius: "10px",
                    }}
                  ></div>
                </a>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const BuscarMais = () => {

    const body = {
      pagina: paginaAtual + 1,
      limite: limite,
      descricao: "",
      cidade_id: cidade.value,
      estado_id: estado.value,
      especialidade_id: credenciado.value,
    };

    Paginador(body).then((response) => {
      
      if ( paginaAtual + 1 > response.data.totalPages) {
        setMostrarVerMais(true);
      } else {
        setMostrarVerMais(false);
      }
      setPaginaAtual((prevPaginaAtual) => prevPaginaAtual + 1);
      setResultado([...resultado, ...response.data.content]);
    });
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          justifyContent: "center",
          lineHeight: "1.5rem",
          marginTop: "1.5rem",
        }}

        className="credenciado-title"
      >
        <p
          style={{
            fontFamily: "Poppins-Bold",
            color: "#969696",
            fontSize: "2.5rem",
          }}
        >
          Rede Credenciada
        </p>
        <p
          style={{
            fontFamily: "Poppins",
            color: "#969696",
            fontSize: "1.5rem",
          }}
        >
          Selecione a cidade, especialidade e encontre atendimento mais perto de
          você
        </p>
      </div>

      <div
        className='select-credenciado-fundo'
        style={{
          width: "100%",
          height: "90px",
          backgroundColor: "#1CB1E2",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            whidth: "90%",
          }}
        >
          <Row>
            <Col md={2}>
              <AsyncSelectEstado
                defaultValue={estado}
                className='select-credenciado'
                id=''
                handleOnChangeSelect={
                  (data) => { data ? setEstado({ value: data.value, label: data.label }) : setEstado({ value: '', label: '' }); }
                }
              ></AsyncSelectEstado>
            </Col>
            <Col md={5}>
              <AsyncSelectCidade
                defaultValue={cidade}
                className='select-credenciado'
                id=''
                handleOnChangeSelect={
                  (data) => data ? setCidade({ value: data.value, label: data.label }) : setCidade({ value: '', label: '' })
                }
                estado={estado.value}
                tipo={true}
              ></AsyncSelectCidade>

            </Col>
            <Col md={5}>
              <AsyncSelectCredenciado
                defaultValue={credenciado}
                className='select-credenciado'
                id=''
                tipo={true}
                handleOnChangeSelect={
                  (data) => { data ? setCredenciado({ value: data.value, label: data.label }) : setCredenciado({ value: '', label: '' }); }
                }
                cidade={cidade.value}
              ></AsyncSelectCredenciado>
            </Col>
          </Row>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#F1F3F9",
          width: "100%",
          display: promo.length > 0 ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "70%",
          }}
        >
          <CarruselPromo></CarruselPromo>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "1rem",
        }}
      >
        <div
          className="credenciado-filtro"
          style={{
            width: "70%",
          }}
        >
          <p
            style={{
              fontFamily: "Poppins",
              color: "#1A4066",
              fontSize: "1.1rem",
            }}
          >
            Resultado da busca 
            { (estado.label) ? " - " + estado.label : "" }  
            { (cidade.label) ? " - " + cidade.label : "" }
            { (credenciado.label) ? " - " + credenciado.label : "" }
          </p>
          <hr
            style={{
              border: "1px solid #1A4066",
              width: "100%",
              backgroundColor: "#1A4066",
            }}
          ></hr>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="credenciado-result"
          style={{
            width: "70%",
          }}
        >
          <Row>
            {resultado.map((item) => {
              return (
                <Col md={3}>
                  <CardResultado {...item}></CardResultado>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>

      { (mostrarVerMais) ? <>
        <div style={{ textAlign:"center", margin: '20px' }}>
          <Button style={{ background: "rgb(28, 177, 226)"}} onClick={() => BuscarMais()}> Ver mais </Button>
        </div>
      </> : <></> }
    </>
  );
};

export default Credenciados;
