/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  DollarSign,
  Edit2,
  FilePlus,
  Filter,
  Printer,
  Play,
  Pause,
  Slash,
  CreditCard,
} from "react-feather";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import ExpandableComponent from "./ExpandableComponent";

import {
  Paginador,
  SaveAssinatura,
  Cancelamento,
  Ativar,
  TrocarCartao,
  Suspender,
  PrintAssinatura,
  PrintExport,
  PagamentoForcado,
} from "../../../service/assinaturas";

import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Label,
  Input,
  Badge,
} from "reactstrap";
import ModalAssinaturas from "./modal";

import SweetAlert2 from "react-sweetalert2";

const Assinaturas = () => {
  const [swalProps, setSwalProps] = useState({});
  const [pagina, setPagina] = useState(1);
  const [tipoModal, setTipoModal] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [mostrarTooltipImprimir, setMostrarTooltipImprimir] = useState(false);
  const [mostrarTooltipCancelar, setMostrarTooltipCancelar] = useState(false);
  const [mostrarTooltipSuspender, setMostrarTooltipSuspender] = useState(false);
  const [mostrarTooltipAtivar, setMostrarTooltipAtivar] = useState(false);
  const [dataForm, setDataForm] = useState([
    {
      usuario: "",
      dependentes: null,
      plano: "",
      preco_final: 0,
      doc_frente: "",
      doc_fundo: "",
    },
  ]);

  const formatValorReal = (valor) => {
    return valor
      ? valor.toLocaleString("pt-br", { style: "currency", currency: "BRL" })
      : "R$ 0,00";
  };

  const [filter, setFilter] = useState([
    {
      email: null,
      cpf: null,
      usuario: null,
      pagina: pagina,
      limite: 10,
    },
  ]);

  const [dados, setDados] = useState([
    {
      usuario: "",
      dependentes: null,
      plano: "",
      preco_final: 0,
      doc_frente: "",
      doc_fundo: "",
    },
  ]);

  const [tooltipId, setTooltipId] = useState(null);

  const showTooltip = (id) => {
    setTooltipId(id);
  };

  const hideTooltip = () => {
    setTooltipId(null);
  };

  const AlterarCartao = (id) => {
    const body = {
      assinatura_id: id,
    };

    TrocarCartao(body)
      .then((result) => {
        toast.success(
          "Envio de e-mail de troca de cartão efetuado com sucesso!"
        );
      })
      .catch((error) => {
        toast.error("Erro ao enviar e-mail de troca de cartão!");
      });
  };

  const EfetuarPagamento = (id) => {
    const body = {
      id: id,
    };
    PagamentoForcado(body)
      .then((result) => {
        console.log("PAGAMENTO", result);
        toast.success("Pagamento efetuado com sucesso!");
        getAssinatura();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Erro ao efetuar o pagamento!: " + error);
      });
  };

  const AtivarAssinatura = (id) => {
    const body = {
      assinatura_id: id,
    };

    Ativar(body)
      .then((result) => {
        console.log("PAGAMENTO", result);
        toast.success("Ativação efetuada com sucesso!");
        getAssinatura();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Erro ao ativar assinatura!");
      });
  };

  const SuspenderAssinatura = (id) => {
    const body = {
      assinatura_id: id,
    };

    Suspender(body)
      .then((result) => {
        console.log("Suspender", result);
        toast.success("Suspenção efetuada com sucesso!");
        getAssinatura();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Erro ao ativar assinatura!");
      });
  };

  const listaDependentes = (dependentes) => {
    console.log("DEPENDENTES", dependentes);
    if (dependentes) {
      return dependentes.map((dependente, index) => {
        return (
          <div key={index}>
            <p>
              • {dependente.Nome} - {dependente.Tipo}
            </p>
          </div>
        );
      });
    }
  };

  const ImprimeAssinatura = (id) => {
    PrintAssinatura(id)
      .then((result) => {
        const base64String = result.data.data.file;

        // Decodifique a string Base64 para obter os dados binários
        const binaryData = atob(base64String);

        // Converta os dados binários para um array de números inteiros
        const bytes = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          bytes[i] = binaryData.charCodeAt(i);
        }

        // Crie um Blob a partir do array de bytes
        const blob = new Blob([bytes], { type: "text/csv" });

        // Use o FileSaver.js para fazer o download do blob
        const fileName = "assinatura.csv";
        saveAs(blob, fileName);
      })
      .catch((error) => {
        console.error("Erro na solicitação:", error);
      });
  };

  const ExpandableComponent = (data) => {
    console.log("DATA", data);
    return (
      <>
        <div
          style={{
            backgroundColor: "#f5f5f5",
            padding: "10px",
          }}
        >
          <Row>
            <Col>
              <h6>Informações da assinatura</h6>
              <p>E-mail: {data.data.email} - Telefone: {data.data.telefone}</p>
              <p>Seguro: {data.data.descricao_seguro}</p>
              <p>
                Valor da assinatura: {formatValorReal(data.data.preco_final)}
              </p>
            </Col>
          </Row>
          <div style={{
            display: data.data.dependentes ? "block" : "none"          
          }}>
          <Col>
            <h6>Dependentes</h6>
            {listaDependentes(data.data.dependentes)}
          </Col>
          </div>
          <Row>
            <h6>Documentação</h6>
            <Col>
              {console.log(data.data)}
              <img
                src={data.data.doc_frente}
                alt="Document Frente"
                style={{ maxWidth: "30vw" }}
              />
            </Col>
            <Col>
              <img
                src={data.data.doc_verso}
                alt="Document Verso"
                style={{ maxWidth: "30vw" }}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const ImprimeExportacao = () => {
    PrintExport().then((result) => {
      console.log("EXPORT", result);

      //   const base64String = result.data.data.file;

      //   // Decodifique a string Base64 para obter os dados binários
      //   const binaryData = atob(base64String);

      //   // Converta os dados binários para um array de números inteiros
      //   const bytes = new Uint8Array(binaryData.length);
      //   for (let i = 0; i < binaryData.length; i++) {
      //     bytes[i] = binaryData.charCodeAt(i);
      //   }

      //   // Crie um Blob a partir do array de bytes
      //   const blob = new Blob([bytes], { type: 'text/csv' });

      //   // Use o FileSaver.js para fazer o download do blob
      //   const fileName = 'assinatura.csv';
      //   saveAs(blob, fileName);
      // }).catch((error) => {
      //   console.error('Erro na solicitação:', error);
    });
  };

  function TrashClick(id) {
    setSwalProps({
      show: true,
      title: "Deseja cancelar a assinatura deste usuário?",
      text: "Não será possível reverter esta ação!",
      icon: "warning",
      input: "text",
      inputPlaceholder: "Motivo do cancelamento",
      showCancelButton: true,
      confirmButtonText: "Sim, remover!",
      cancelButtonText: "Não, cancelar!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      reverseButtons: true,
      focusCancel: true,
      focusConfirm: false,

      preConfirm: (motivo) => {
        RemoverAssinatura(id, motivo);
        setSwalProps({ show: false });
      },
    });
  }

  const RemoverAssinatura = (id, motivo) => {
    const body = {
      assinatura_id: id,
      motivo_cancelamento: motivo,
    };

    Cancelamento(body)
      .then((result) => {
        toast.success("Assinatura cancelada com sucesso!");
        getAssinatura();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Erro ao cancelar a assinatura!");
      });
  };

  const getAssinatura = () => {
    Paginador(filter)
      .then((result) => {
        console.log("Resultado da consulta: ", result.data.data);
        setDados(result.data.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Erro ao carregar os dados!");
      });
  };
  useEffect(() => {
    getAssinatura();
  }, []);

  const onSubmit = () => {
    console.log("DADOS ENVIADOS:", dataForm);
    SaveAssinatura(dataForm)
      .then((result) => {
        console.log(result.data);
        toast.success("Usuário cadastrado com sucesso!");
        setOpenModal(false);
        getAssinatura();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Erro ao cadastrar o usuário!");
      });
  };

  const textBadge = (status) => {
    switch (status) {
      case "ATIVO":
        return (
          <Badge color="success" pill>
            {"ATIVO"}
          </Badge>
        );
      case "AGUARDANDO_PAGAMENTO":
        return (
          <Badge color="info" pill>
            {"AGUARDANDO PAGAMENTO"}
          </Badge>
        );
      case "CANCELADO":
        return (
          <Badge color="danger" pill>
            {"CANCELADO"}
          </Badge>
        );
      case "SUSPENSO":
        return (
          <Badge color="warning" pill>
            {"SUSPENSO"}
          </Badge>
        );
      default:
        return (
          <Badge color="info" pill>
            {status}
          </Badge>
        );
    }
  };

  const mostrarTooltip = (status) => {};

  const columns = [
    {
      name: "Usuário",
      selector: "usuario",
      sortable: true,
      width: "26%",
      cell: (row) => <>{row.usuario}</>,
    },
    {
      name: "CPF",
      selector: "usuario",
      sortable: true,
      width: "16%",
      cell: (row) => <>{row.cpf}</>,
    },
    {
      name: "Plano",
      selector: "plano",
      width: "20%",
      sortable: true,
      cell: (row) => <>{row.plano}</>,
    },
    {
      name: "Status",
      selector: "status",
      width: "20%",
      sortable: true,
      cell: (row) => <>{textBadge(row.status)}</>,
    },
    {
      name: "Ações",
      selector: "acoes",
      width: "15%",
      sortable: true,
      right: true,
      cell: (row) => (
        <>
          <div
            style={{ display: row.status !== "CANCELADO" ? "block" : "none" }}
          >
            <div
              onMouseOver={() => showTooltip("trocar-cartao-" + row.id)}
              onMouseLeave={() => hideTooltip()}
              style={{
                position: "relative",
                display: "inline-block",
                cursor: "pointer",
              }}
            >
              <CreditCard size={16} onClick={() => AlterarCartao(row.id)} />
              {tooltipId === "trocar-cartao-" + row.id && (
                <div className="tooltip-adm">Alterar Cartão</div>
              )}
            </div>
            &nbsp;&nbsp;
          </div>
          <div
            style={{ display: row.status === "SUSPENSO" ? "block" : "none" }}
          >
            <div
              onMouseOver={() => showTooltip("ativar-" + row.id)}
              onMouseLeave={() => hideTooltip()}
              style={{
                position: "relative",
                display: "inline-block",
                cursor: "pointer",
              }}
            >
              <Play size={16} onClick={() => AtivarAssinatura(row.id)} />
              {tooltipId === "ativar-" + row.id && (
                <div className="tooltip-adm">Ativar</div>
              )}
            </div>
            &nbsp;&nbsp;
          </div>
          <div
            style={{ display: row.status === "SUSPENSO" ? "block" : "none" }}
          >
            <div
              onMouseOver={() => showTooltip("pagar-" + row.id)}
              onMouseLeave={() => hideTooltip()}
              style={{
                position: "relative",
                display: "inline-block",
                cursor: "pointer",
              }}
            >
              <DollarSign size={16} onClick={() => EfetuarPagamento(row.id)} />
              {tooltipId === "pagar-" + row.id && (
                <div className="tooltip-adm">Cobrar Novamente</div>
              )}
            </div>
            &nbsp;&nbsp;
          </div>
          <div style={{ display: row.status === "ATIVO" ? "block" : "none" }}>
            <div
              onMouseOver={() => showTooltip("suspender-" + row.id)}
              onMouseLeave={() => hideTooltip()}
              style={{
                position: "relative",
                display: "inline-block",
                cursor: "pointer",
              }}
            >
              <Pause size={16} onClick={() => SuspenderAssinatura(row.id)} />
              {tooltipId === "suspender-" + row.id && (
                <div className="tooltip-adm">Suspender</div>
              )}
            </div>
            &nbsp;&nbsp;
          </div>
          <div style={{ display: row.status === "ATIVO" ? "block" : "none" }}>
            <div
              onMouseOver={() => showTooltip("cancelar-" + row.id)}
              onMouseLeave={() => hideTooltip()}
              style={{
                position: "relative",
                display: "inline-block",
                cursor: "pointer",
              }}
            >
              <Slash size={16} onClick={() => TrashClick(row.id)} />
              {tooltipId === "cancelar-" + row.id && (
                <div className="tooltip-adm">Cancelar</div>
              )}
            </div>
            &nbsp;&nbsp;
          </div>
          <div style={{ display: "block" }}>
            <div
              onMouseOver={() => showTooltip("imprimir-" + row.id)}
              onMouseLeave={() => hideTooltip()}
              style={{
                position: "relative",
                display: "inline-block",
                cursor: "pointer",
              }}
            >
              <Printer size={16} onClick={() => ImprimeAssinatura(row.id)} />
              {tooltipId === "imprimir-" + row.id && (
                <div className="tooltip-adm">Imprimir</div>
              )}
            </div>
          </div>
          &nbsp;&nbsp;
        </>
      ),
    },
  ];

  return (
    <>
      <ModalAssinaturas
        tipoModal={tipoModal}
        openModal={openModal}
        setOpenModal={setOpenModal}
        dataForm={dataForm}
        setDataForm={setDataForm}
        onSubmit={onSubmit}
      />
      <SweetAlert2
        {...swalProps}
        didClose={() => setSwalProps({ show: false })}
      />
      <br />
      <Row>
        <Card>
          <CardBody>
            <Row>
              <Col sm="2">
                <h4>Assinaturas</h4>
              </Col>
              <Col
                sm="10"
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  alignItems: "right",
                }}
              >
                <Button
                  className="buttonSideFilter"
                  onClick={() => setOpenFilter(!openFilter)}
                >
                  <Filter size={18} />
                </Button>
                <Button
                  onClick={() => ImprimeExportacao()}
                  style={{
                    backgroundColor: "#1CB1E2",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    marginTop: "-4px",
                  }}
                >
                  <Printer size={18} />
                  &nbsp; Exportar Assinaturas
                </Button>
                &nbsp;
                {/*
                <Button
                  size="sm"
                  className="buttonSide"
                  onClick={() => handleOpenModal("Criar")}
                >
                  <FilePlus size={18} />
                  &nbsp; Nova Assinatura
                </Button>
                */}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
      <br />
      <div
        style={{
          display: openFilter ? "block" : "none",
          transitionTimingFunction: "ease-in-out",
          transitionDuration: "2s",
          transform: "translateY(-10px)",
        }}
      >
        <Row>
          <Card>
            <CardBody>
              <Row>
                <Col sm="1">
                  <h5>Filtros</h5>
                </Col>
                <Col sm="11">
                  <Row>
                    <Col sm="3">
                      <Label>Usuário:</Label>
                      <Input
                        type="text"
                        value={filter.usuario}
                        onChange={(e) => {
                          setFilter({ ...filter, usuario: e.target.value });
                        }}
                      />
                    </Col>
                    <Col sm="3">
                      <Label>CPF:</Label>
                      <Input
                        type="text"
                        value={filter.cpf}
                        onChange={(e) => {
                          setFilter({ ...filter, cpf: e.target.value });
                        }}
                      />
                    </Col>
                    <Col sm="3">
                      <Label>E-mail:</Label>
                      <Input
                        type="text"
                        value={filter.email}
                        onChange={(e) => {
                          setFilter({ ...filter, email: e.target.value });
                        }}
                      />
                    </Col>
                    <Col sm="3">
                      <Label>Status:</Label>
                      <Input
                        type="select"
                        value={filter.status}
                        onChange={(e) => {
                          setFilter({ ...filter, status: e.target.value });
                        }}
                      >
                        <option value=""></option>
                        <option value="ATIVO">Ativo</option>
                        <option value="CANCELADO">Cancelado</option>
                        <option value="SUSPENSO">Suspenso</option>
                        <option value="AGUARDANDO_PAGAMENTO">
                          Aguardando Pagamento
                        </option>
                      </Input>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm="12">
                      <Button
                        size="sm"
                        onClick={() => getAssinatura()}
                        className="buttonSide"
                        style={{ float: "right", width: "100px" }}
                      >
                        Filtrar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
      </div>

      <Row>
        <Card>
          <CardBody>
            <Row>
              <DataTable
                columns={columns}
                data={dados}
                pagination
                highlightOnHover
                responsive
                striped
                expandableRows
                expandableRowsComponent={(row) => ExpandableComponent(row)}
              />
            </Row>
          </CardBody>
        </Card>
      </Row>
    </>
  );
};

export default Assinaturas;
