import React, {useEffect, useState} from 'react'
import DataTable from 'react-data-table-component';
import { BookOpen, Edit2, Filter, Trash2 } from 'react-feather';
import { Card, CardBody, Row, Col, Button, Label, Input } from 'reactstrap';
import ModalUsuarios from './modal';
import { toast } from 'react-toastify';
import SweetAlert2 from 'react-sweetalert2';
import { PaginadorDash,
        SaveBanner,
        UpdateBanner,
        RemoveBanner,
        GetBanner    
} from '../../../service/banner';

const Banner = () => {

    const [swalProps, setSwalProps] = useState({});
    const [tipoModal, setTipoModal] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [openFilter, setOpenFilter] = useState(false)
    const [result, setResult] = useState([{}])
    const [dados, setDados] = useState({
        descricao: '',
        imagem: undefined,
        ativo: true,
        data_de: null,
        data_ate: null
    })
    const [tooltipId, setTooltipId] = useState(null);

    const showTooltip = (id) => {
        setTooltipId(id);
    };

    const hideTooltip = () => {
        setTooltipId(null);
    };
    
    const handleOpenModal = async (tipo, id) => {
        
        if (tipo === "Criar") { 
            setTipoModal(tipo);
            setOpenModal(true);
            setDados({});
        } else { 
            const response = await GetBanner(id);
            if (response.status === 200 || response.status === 201) {
                const dados = response.data;
                setDados({
                    ...dados,
                    data_de: dados.data_de ? dados.data_de.split("T")[0] : null,
                    data_ate: dados.data_ate ? dados.data_ate.split("T")[0] : null,
                });

                setTipoModal(tipo)
                setOpenModal(true)

                return;
            } else {
                setOpenModal(false)
                toast.error("Erro ao buscar banner");
            }
        } 
    }

    const getBanners = () => {
        const body = {
            "pagina": 1,
            "limite": 10
        }
        PaginadorDash(body).then((response) => {
            if(response.status === 200 || response.status === 201){
                setResult(response.data.data.content)
                
            }else{
                console.log(response)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        getBanners()
    }, [])

    function TrashClick(id){
        
        setSwalProps({
            show: true,
            title: 'Deseja remover o Banner?',
            text: "Não será possível reverter esta ação!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, remover!',
            cancelButtonText: 'Não, cancelar!',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            reverseButtons: true,
            focusCancel: true,
            focusConfirm: false,
    
            preConfirm: () => {
                RemoverBanner(id);
                setSwalProps({show: false});
            }
            
        }); 
    }
    
    const RemoverBanner = (id) => {
   
        RemoveBanner(id).then((result) => {
          toast.success("Banner removido com sucesso!");
          getBanners();
        }
        ).catch((error) => {
            console.log(error);
            toast.error("Erro ao remover Banner!");
            
      }
      );
    };

    const onSubmit = (fileName) => {
        dados.midia = fileName;

        if (!dados.id) { 
            SaveBanner(dados).then((response) => {
                if(response.status === 200 || response.status === 201){
                    toast.success('Banner salvo com sucesso!')
                    getBanners()
                    setOpenModal(false)
                }else{
                    toast.error('Erro ao salvar Banner!')
                }
            }
            ).catch((err) => {
                console.log(err)
                toast.error('Erro ao salvar Banner!')
            })

            return;
        } 

        var dataDe = null;
        if (dados.data_de) dataDe = new Date(dados.data_de).toISOString().split("T")[0];
        var dataAte = null;
        if (dados.data_ate) dataAte = new Date(dados.data_ate).toISOString().split("T")[0];

        const input = {
            id: dados.id,
            descricao: dados.descricao,
            link: dados.link,
            ativo: dados.ativo,
            dataDe: dataDe,
            dataAte: dataAte
        }

        console.log(dados);
        console.log("INPUT UPDATE")
        console.log(input);
        
        UpdateBanner(input).then((response) => {
            console.log(response)
            if (response.status === 200 || response.status === 201){
                toast.success('Banner salvo com sucesso!')
                getBanners()
                setOpenModal(false)
            }else{
                toast.error('Erro ao salvar Banner!')
            }
        }
        ).catch((err) => {
            console.log(err)
            toast.error('Erro ao salvar Banner!')
        })

    }

    const columns = [
        {
            name: 'Descrição',
            selector: 'descricao',
            sortable: true,
            width: '50%'
        },
        {
            name: 'Imagem',
            selector: 'imagem',
            sortable: false,
            width: '30%',
            cell: row => <div style={{
                width: '200px',
                height: '100px',
                backgroundImage: `url(${row.imagem})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'

            }}></div>
        },
        {
            name: 'Ações',
            selector: 'acoes',
            sortable: true,
            right: true,
            width: '10%',
            cell: row => <>
          <div onMouseOver={() => showTooltip('editar-' + row.id)} onMouseLeave={() => hideTooltip()}
              style={{ position: 'relative', display: 'inline-block', cursor: 'pointer', }
          }>
            <Edit2 style={{cursor: "pointer"}} size={18} onClick={() => handleOpenModal("Editar", row.id)} />
            {tooltipId === 'editar-' + row.id && (
              <div className="tooltip-adm">Editar </div>
            )}
          </div>
          &nbsp;&nbsp;
          <div onMouseOver={() => showTooltip('remover-' + row.id)} onMouseLeave={() => hideTooltip()} 
            style={{ position: 'relative', display: 'inline-block', cursor: 'pointer', }
          }>
            <Trash2 style={{cursor: "pointer"}} onClick={() => TrashClick(row.id)} size={18}></Trash2>
            {tooltipId === 'remover-' + row.id && (
              <div className="tooltip-adm">Remover</div>
            )}
          </div>
          </> 
        },
    ];
    
    return (
    <>
        <ModalUsuarios 
        tipoModal={tipoModal} 
        openModal={openModal} 
        setOpenModal={setOpenModal} 
        dados={dados} 
        setDados={setDados} 
        onSubmit={onSubmit}
        />
        <SweetAlert2 {...swalProps} didClose={
        () => setSwalProps({show: false})
        }/>
    <br />
    <Row>
        <Card>
            <CardBody>
                <Row>
                    <Col sm='2'>
                <h4>Banner</h4>
                    </Col>
                    <Col sm='10' style={{
                        justifyContent: 'flex-end',
                        display: 'flex',
                        alignItems: 'right'
                    }}>
                <Button className='buttonSideFilter' onClick={() => setOpenFilter(!openFilter)}><Filter size={18} /></Button>&nbsp;        
                <Button size='sm' className='buttonSide' onClick={() => handleOpenModal("Criar")}><BookOpen size={18} />&nbsp; Novo Banner</Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        
    </Row>
    <br />
    <div 
    style={{
        display: openFilter ? 'block' : 'none',
        transitionTimingFunction: 'ease-in-out',
        transitionDuration: '2s',
        transform: 'translateY(-10px)'
    }}
    >
    <Row>
        <Card >
            <CardBody>
                <Row>
                    <Col sm='1'>
                    <h5>Filtros</h5>
                    </Col>
                    <Col sm='11' >
                    <Row>
                        <Col sm='3'>
                        <Label>Nome</Label>
                        <Input type='text' />
                        </Col>
                        <Col sm='3'>
                        <Label>E-mail</Label>
                        <Input type='text' />
                        </Col>
                    </Row>                       
                    
                    </Col>
                </Row>
            </CardBody>
        </Card>
        
    </Row>
    </div>
    
    <Row>
        <Card>
            <CardBody>
                <Row>
                    <DataTable
                        columns={columns}
                        data={result}
                        pagination
                        highlightOnHover
                        responsive
                        striped
                    />
                </Row>
            </CardBody>
        </Card>
    </Row>
                        
    </>
    )
    }

    export default Banner