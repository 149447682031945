import { Button, Col, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const ModalUsuarios = (
    {
        tipoModal,
        openModal,
        setOpenModal,
        dataForm,
        setDataForm,
        onSubmit
    }
) => {


    return (
        <>
        <Modal isOpen={openModal} style={{width: '600px', minWidth: '600px'}} className='modal-dialog-centered'>
            <ModalHeader>
                {tipoModal} Usuário
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm='6'>
                        <Label>Primeiro Nome</Label>
                        <Input 
                        type='text' 
                        value={dataForm.primeiro_nome} 
                        onChange={(e) => setDataForm({...dataForm, primeiro_nome: e.target.value})} />
                    </Col>
                    <Col sm='6'>
                        <Label>Sobrenome</Label>
                        <Input
                        type='text'
                        value={dataForm.sobrenome}
                        onChange={(e) => setDataForm({...dataForm, sobrenome: e.target.value})}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col sm='2'>
                        <Label>DDD</Label>
                        <Input
                        type='number'
                        maxLength={2}
                        minLength={2}
                        value={dataForm.ddd}
                        onChange={(e) => setDataForm({...dataForm, ddd: e.target.value})}
                        />
                    </Col>
                    <Col sm='5'>
                        <Label>Telefone</Label>
                        <Input
                        type='number'
                        maxLength={9}
                        minLength={8}
                        value={dataForm.telefone}
                        onChange={(e) => setDataForm({...dataForm, telefone: e.target.value})}
                        />
                    </Col>
                    <Col sm='5'>
                        <Label>Data de Nascimento</Label>
                        <InputGroup>
                            <DatePicker
                            className="form-control"
                            selected={dataForm.data_nascimento}
                            onChange={(date) => setDataForm({...dataForm, data_nascimento: new Date(date)})}
                            dateFormat='dd/MM/yyyy'
                            />
                        </InputGroup>   
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col sm='4'>
                        <Label>Email</Label>
                        <Input
                        type='email'
                        value={dataForm.email}
                        onChange={(e) => setDataForm({...dataForm, email: e.target.value})}
                        />
                    </Col>
                    <Col sm='4'>
                        <Label>Senha</Label>
                        <Input
                        type='password'
                        autoComplete="new-password"
                        value={dataForm.senha}
                        onChange={(e) => setDataForm({...dataForm, senha: e.target.value})}
                        />
                    </Col>
                    <Col sm='4'>
                        <Label>Confirma Senha</Label>
                        <Input
                        type='password'
                        value={dataForm.confirma_senha}
                        onChange={(e) => setDataForm({...dataForm, confirma_senha: e.target.value})}
                        />
                    </Col>
                </Row>
                        

            </ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={() => onSubmit()}>Salvar</Button>
                <Button color='secondary' onClick={() => setOpenModal(false)}>Cancelar</Button>
            </ModalFooter>
        </Modal>            
        </>
    )
}

export default ModalUsuarios