import { Link } from 'react-router-dom';

const SideBarLeftADM = () => {

    const userData = JSON.parse(localStorage.getItem('user'));
    const userType = userData.role;

    console.log("role", userType);  

    const pages = [
        {
            name: 'Inicio',
            path: '/adm/dashboardadm'
        },
        {
            name: 'Assinaturas',
            path: '/adm/assinaturas'
        },
        {
            name: 'Planos',
            path: '/adm/planos'
        },
        {
            name: 'Banner',
            path: '/adm/banner'
        },
        {
            name: 'Blog',
            path: '/adm/blog'
        },
        {
            name: 'Credenciados',
            path: '/adm/credenciados'
        },
        {
            name: 'Documentos',
            path: '/adm/documentos'
        },
        {
            name: 'Usuarios',
            path: '/adm/usuarios'
        },

        // {
        //     name: 'Especialidades',
        //     path: '/adm/especialidades'
        // },
        // {
        //     name: 'Franqueados',
        //     path: '/adm/franqueados'
        // },
    ]

    return (
        // css code from cardMenu animated
        <div style={{height: "100%", alignItems: "flex-start"}}>
            <div className="sidearLeft" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{
                justifyContent: "flex-start",
                paddingTop: "20vh",
            }}>
                {pages.map((page, index) => 
                    {
                        return (
                            <Link className="bt-menu-adm" to={page.path} key={index} id="v-pills-home-tab" data-toggle="pill" role="tab"
                            
                    aria-controls="v-pills-home" aria-selected="true">{page.name}
                    </Link>
                        )
                    }
                )}
                    
                
            </div>
        </div>
    );
    }

    export default SideBarLeftADM;