import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../../components/footer";
import HeaderADM from "../../../components/headerADM";
import "../../../App.css";
import "../../../AppMobile.css";
import { ToastContainer } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import SideBarLeftADM from "../../../components/sidebarleftADM";

const StructureADM = () => {
  useEffect(() => {
    console.log("USE EFFECT STRUCTURE ADM");
    const token = localStorage.getItem("token");

    if (token === null || token === undefined || token === "") {
      if (window.location.pathname !== "/assinatura") {
        window.location.href = "/";
      }
    }
  }, []);

  return (
    <>
      <div style={{ maxWidth: "1366px", margin: "0 auto" }}>

        <div
          style={{
            width: "98%", 
            height: "100%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
          >
          <Row
          style={{
            minHeight: '85vh',
            height: '100%'
          }}
          >
            <Col sm="2">
              <SideBarLeftADM />
            </Col>
            <Col sm="10">
              
              <HeaderADM />
              <Outlet />
            </Col>
          </Row>
        </div>
        <footer>
          <Footer />
        </footer>
        <ToastContainer />
      </div>
    </>
  );
};

export default StructureADM;
