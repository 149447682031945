import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './routes/home/home';
import Franqueado from './routes/home/franqueado';
import ErrorPage from './routes/errorPage';
import Assinatura from './pages/home/assinatura';
import Especialidades from './pages/home/especialidades';
import Sobre from './pages/home/sobre';
import Blog from './pages/admin/blog';
import App from './App';
import Usuarios from './pages/admin/usuarios';
import DashboardAdm from './pages/admin/dashoardadm';
import Assinaturas from './pages/admin/assinaturas';
import Credenciadosadm from './pages/admin/credenciados';
import PlanosAdm from './pages/admin/planos';
import FranqueadoPage from './routes/home/franqueado';
import FranqueadosAdm from './pages/admin/franqueados';
import EspecialidadesAdm from './pages/admin/especialidades';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BtCompra } from './components/btcompra';
import BlogList from './pages/home/blog';
import SejaCredenciado from './pages/home/seja-credenciado';
import BlogPage from './pages/home/blogpage';
import DashboardCliente from './pages/home/dashboard/dashboardcliente';
import Banner from './pages/admin/banner';
import Senha from './pages/home/home/senha';
import DocumentosAdm from './pages/admin/documentos';
import Credenciados from './pages/home/credenciados';

const BrowserRouter = createBrowserRouter([
  {
    path: '/',
    exact: true,
    //element: <StructureADM />,
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
                {
                  path: '/',
                  element: <Home />,
                },
                {
                  path: '/senha',
                  element: <Senha />,
                },
                {
                  path: '/franqueado',
                  element: <Franqueado />,
                },
                {
                  path: '/assinatura',
                  element: <Assinatura />,
                },
                {
                  path: '/especialidades',
                  element: <Especialidades />,
                },
                {
                  path: '/credenciados',
                  element: <Credenciados />,
                },
                {
                  path: '/sobre',
                  element: <Sobre />,
                },
                {
                  path: '/blog',
                  element: <BlogList />,
                },
                {
                  path: '/seja-credenciado',
                  element: <SejaCredenciado />,
                },
                {
                  path: '/page/',
                  element: <BlogPage />,
                },
                {
                  path: '/adm/blog',
                  element: <Blog />,
                },
                {
                  path: '/adm/banner',
                  element: <Banner />,
                },
                {
                  path: '/adm/credenciados',
                  element: <Credenciadosadm />,
                },
                {
                  path: '/cliente/dashboardcliente',
                  element: <DashboardCliente />,
                },
                {
                  path: '/adm/dashboardadm',
                  element: <DashboardAdm />,
                },
                {
                  path: '/adm/usuarios',
                  element: <Usuarios />,
                },
                {
                  path: '/adm/assinaturas',
                  element: <Assinaturas />,
                },
                {
                  path: '/adm/especialidades',
                  element: <EspecialidadesAdm />,
                },
                {
                  path: '/adm/franqueados',
                  element: <FranqueadosAdm />,
                },
                {
                  path: '/adm/planos',
                  element: <PlanosAdm />,
                },
                {
                  path: '/adm/documentos',
                  element: <DocumentosAdm />,
                },
              ]
  }
]);


ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
       
    <ToastContainer/>
    <RouterProvider router={BrowserRouter}>      
    </RouterProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
