/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDropzone } from "react-dropzone";
import AsyncSelectCidade from "../../../../components/asynselectcidade";
import "react-datepicker/dist/react-datepicker.css";
import {PlusCircle, Trash2 } from "react-feather";
import { toast } from "react-toastify";
import {
  SaveEspecialidade, 
  Paginador,
  RemoveEspecialidade,
  SaveTipoEspecialidade,
  TipoPaginador  
} from '../../../../service/especialidade'
import AsyncSelectCredenciado from "../../../../components/asynselectcredenciado";

const ModalCredenciadosAdm = ({
  tipoModal,
  openModal,
  setOpenModal,
  formData,
  setFormData,
  onSubmit,
  setTipoModal
}) => {
  
  const [descricaoEspecialidade, setDescricaoEspecialidade] = useState("");
  const [especialidades, setEspecialidades] = useState([]);
  const [modalTipoCredenciado, setModalTipoCredenciado] = useState(false);
  const [tipoCredenciado, setTipoCredenciado] = useState([]);
  const [descricaoTipoCredenciado, setDescricaoTipoCredenciado] = useState("");
  const [especialidadesSelecionadas, setEspecialidadesSelecionadas] = useState([]);
  const [modalEspecialidades, setModalEspecialidades] = useState(false);
  const [especcialidadeLabel, setEspecialidadeLabel] = useState("Selecione uma especialidade");

  const selectEspecialidades = () => {
    return (
      <>
        {especialidadesSelecionadas.length > 0 ? especialidadesSelecionadas.map((item, index) => {
          
          return (
            <Badge
              key={index.id}
              style={{
                backgroundColor: "#1990CF",
                borderRadius: "15px",
                marginRight: "5px",
              }}
            >
              {item.label}
              <Trash2
                onClick={() => removeEspecialidade(item)}
                size={18}
                style={{ marginLeft: "5px", marginTop: "-3px" }}
              />
            </Badge>
          );
        }) : null}
      </>
    );
  };

  const deleteEspecialidade = (data) => {
    RemoveEspecialidade(data).then((response) => {
      toast.success('Especialidade removida com sucesso!')
      getEspecialidades();
    }
    ).catch((err) => {
      console.log(err)
    })
  }


  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        //print base64 string
        const base64String = btoa(
          String.fromCharCode(...new Uint8Array(binaryStr))
        );
        setFormData({ ...formData, imagem: base64String });
      };
      reader.readAsArrayBuffer(file);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: "image/*", maxFiles: 1, maxSize: 10485760 });

  const getEspecialidades = () => {
    Paginador().then((response) => {
      if (response.status === 200) {
        setEspecialidades(response.data.content);
      }
    });
  }

  const addEspecialidade = (data) => {    
    const lista = []
    setEspecialidadesSelecionadas([...especialidadesSelecionadas, data]);
    especialidadesSelecionadas.map((item) => {
      lista.push(item.value)
    })
    lista.push(data.value)
    setFormData({ ...formData, especialidades: lista });
  };

  const removeEspecialidade = (data) => {
    const lista = []
    const especialidades = especialidadesSelecionadas.filter(
      (item) => item.label !== data.label
    );
    setEspecialidadesSelecionadas(especialidades);
    especialidades.map((item) => {
      lista.push(item.value)
    })
    setFormData({ ...formData, especialidades: lista });    
  };
 
  const setNovaEspecialidades = () => {
    SaveEspecialidade({descricao: descricaoEspecialidade}).then((response) => {
        toast.success('Especialidade criada com sucesso!')
        getEspecialidades();
        setDescricaoEspecialidade("");
        setModalEspecialidades(false);
      
    }
    ).catch((err) => {
      console.log(err)
    })
  }  

  const setNovaTipoCredenciado = () => {
    SaveTipoEspecialidade({descricao: descricaoTipoCredenciado}).then((response) => {
        toast.success('Tipo de credenciado criado com sucesso!')
        getEspecialidades();
        setDescricaoEspecialidade("");
        setModalTipoCredenciado(false);
    }
    ).catch((err) => {
      console.log(err)
    })
  }

  const getTipoCredenciado = () => {
    TipoPaginador().then((response) => {
      if (response.status === 200) {
        setTipoCredenciado(response.data.content);
      }
    });
  }

  useEffect(() => {
    // getEspecialidades();
    // getTipoCredenciado();
    if (tipoModal === "Editar") {
      console.log("LISTA DE ESPECIALIDADES:", formData.especialidades)
      var lista = []  
      formData.especialidades.map((item) => {
        lista.push({value: item.especialidade_id, label: item.descricao})
      })
      setEspecialidadesSelecionadas(lista);
    }
  }, [tipoModal]);

  const getExtension = (base64) => {
    if (base64 === "") {
      const ext = base64.charAt(0);
      switch (ext) {
        case "/":
          return "jpg";
        case "i":
          return "png";
        case "g":
          return "gif";
        case "b":
          return "bmp";
        default:
          return "jpg";
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={modalEspecialidades}
        style={{ width: "400px", minWidth: "400px" }}
        className="modal-dialog-centered"
      >
        <ModalBody>
          <Row>
            <Col sm="12">
              <Label>Especialidades</Label>
              <Input
                type="text"
                value={descricaoEspecialidade}
                onChange={(e) => setDescricaoEspecialidade(e.target.value)}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="12">
              <Button
                block={true}
                color="primary"
                onClick={() => setNovaEspecialidades()}
              >
                Adicionar
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="12">
              <div
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                  border: "1px solid #ccc",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <Row>
                <Col sm="12">
                  {especialidades.length > 0 ? (
                    especialidades.map((item, index) => {
                      return (
                        <>
                        <Row key={index.id}>
                          <Col sm="10">
                            {item.descricao}
                          </Col>
                          <Col sm="2">
                            <Trash2
                              size={18}
                              style={{ marginLeft: "5px", marginTop: "-3px" }}
                            />
                          </Col>
                        </Row>
                        <hr />
                        </>
                      );
                    }
                    )
                  ) : (
                    <p>Nenhuma especialidade cadastrada</p>
                  )}
                </Col>
              </Row>
              </div>
            </Col>
          </Row>
           <br />
           <Button block={true} color="secondary" onClick={() => setModalEspecialidades(false)}>
            Cancelar
          </Button>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalTipoCredenciado}
        style={{ width: "400px", minWidth: "400px" }}
        className="modal-dialog-centered"
      >
        <ModalBody>
          <Row>
            <Col sm="12">
              <Label>Tipo Credenciado </Label>
              <Input
                type="text"
                value={descricaoTipoCredenciado}
                onChange={(e) => setDescricaoTipoCredenciado(e.target.value)}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="12">
              <Button
                block={true}
                color="primary"
                onClick={() => setNovaTipoCredenciado()}
              >
                Adicionar
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="12">
              <div
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                  border: "1px solid #ccc",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <Row>
                <Col sm="12">
                  {tipoCredenciado.length > 0 ? (
                    tipoCredenciado.map((item, index) => {
                      return (
                        <>
                        <Row key={index.id}>
                          <Col sm="10">
                            {item.descricao}
                          </Col>
                          <Col sm="2">
                            <Trash2
                              size={18}
                              style={{ marginLeft: "5px", marginTop: "-3px" }}
                            />
                          </Col>
                        </Row>
                        <hr />
                        </>
                      );
                    }
                    )
                  ) : (
                    <p>Nenhuma tipo credenciado cadastrado</p>
                  )}
                </Col>
              </Row>
              </div>
            </Col>
          </Row>
           <br />
           <Button block={true} color="secondary" onClick={() => setModalTipoCredenciado(false)}>
            Cancelar
          </Button>
        </ModalBody>

      </Modal>
      <Modal
        isOpen={openModal}
        style={{ width: "800px", minWidth: "800px" }}
        className="modal-dialog-centered"
      >
        <ModalHeader>{tipoModal} Credenciado</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="4">
              <Row>
                <Col sm="12">
                  <Label>Nome</Label>
                  <Input
                    type="text"
                    value={formData.nome}
                    onChange={(e) =>
                      setFormData({ ...formData, nome: e.target.value })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col sm="4">
              <Row>
                <Col sm="12">
                  <Label>Telefone</Label>
                  <Input
                    type="text"
                    value={formData.telefone}
                    onChange={(e) =>
                      setFormData({ ...formData, telefone: e.target.value })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col sm="4">
              <Row>
                <Col sm="12">
                  <Label>Cidade</Label>
                  <AsyncSelectCidade
                    defaultValue={formData.cidade_id}
                    className=""
                    id=""
                    handleOnChangeSelect={(data) => {
                      data
                        ? setFormData({
                            ...formData,
                            cidade_id: { value: data.value, label: data.label },
                          })
                        : setFormData({
                            ...formData,
                            cidade_id: { value: "", label: "" },
                          });
                    }}
                    estado={null}
                    tipo={false}
                  ></AsyncSelectCidade>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <Row>            
            <Col sm="6">
              <Row>
                <Col sm="12">
                  <Label>Link Instagram</Label>
                  <Input
                    type="text"
                    value={formData.link_instagram}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        link_instagram: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col sm="6">
              <Row>
                <Col sm="12">
                  <Label>Link Facebook</Label>
                  <Input
                    type="text"
                    value={formData.link_facebook}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        link_facebook: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="4">
              <Row>
                <Col sm="12">
                  <Label>CEP</Label>
                  <Input
                    type="text"
                    value={formData.cep}
                    onChange={(e) =>
                      setFormData({ ...formData, cep: e.target.value })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col sm="4">
              <Row>
                <Col sm="12">
                  <Label>Endereço</Label>
                  <Input
                    type="text"
                    value={formData.endereco}
                    onChange={(e) =>
                      setFormData({ ...formData, endereco: e.target.value })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col sm="4">
              <Row>
                <Col sm="12">
                  <Label>Bairro</Label>
                  <Input
                    type="text"
                    value={formData.bairro}
                    onChange={(e) =>
                      setFormData({ ...formData, bairro: e.target.value })
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <Row>
            { /*
            <Col sm="8">
              <Row>
                <Col sm="12">
                <Label>Tipo Credenciado</Label>
                <SelectAsyncTipoCredenciado
                defaultValue={formData.tipo_id}
                className=''
                id=''
                
                handleOnChangeSelect={
                  (data) => { data ? setFormData({ ...formData, tipo_id: { value: data.value, label: data.label } }) : setFormData({ ...formData, tipo_id: { value: "", label: "" } }) }
                }
                cidade=""
                ></SelectAsyncTipoCredenciado>
                </Col>
                
              </Row>
            </Col>
            */}
            <Col sm="4" style={{
              paddingTop: "5%"
            }}>
              <Input 
              type="switch"
              id="exampleCustomSwitch"
              name="customSwitch"
              label="Mostrar como promoção"
              onChange={(e) =>
                setFormData({ ...formData, promocao: e.target.checked })
              }                          
              />
              <Label>&nbsp;&nbsp;Mostrar como promoção</Label>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="6">
              <Row>
                <Col sm="12">
                  <Label>Imagem</Label>
                  {formData.imagem === undefined ? (
                    <div
                      {...getRootProps()}
                      style={{
                        width: "100%",
                        height: "18.7rem",
                        border: "1px solid #ccc",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <input {...getInputProps()} />
                      <p>Clique aqui ou arraste uma imagem para cá</p>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "18.7rem",
                        border: "1px solid #ccc",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        borderRadius: "10px",
                        cursor: "pointer",
                        padding: "10px",
                      }}
                    >
                      <img
                        onClick={() =>
                          setFormData({ ...formData, imagem: undefined })
                        }
                        src={
                          formData.imagem.substring(0, 4) === "http" ? (
                            formData.imagem
                          ) : (
                            "data:image/" +
                            getExtension(formData.imagem) +
                            ";base64," +
                            formData.imagem
                          )
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Col sm="6">
              <Row>
                <Col sm="12">
                  <Label>Especialidade</Label>
                  &nbsp;
                  <PlusCircle onClick={() => setModalEspecialidades(true)} size={18} />
                  <AsyncSelectCredenciado
                defaultValue={especcialidadeLabel}
                className=''
                id=''
                tipo={true}
                handleOnChangeSelect={
                  (data) => { 
                    setEspecialidadeLabel(data.label)
                    addEspecialidade(data)
                  }
                }
                cidade=""
                ></AsyncSelectCredenciado>
                </Col>
              </Row>
              <Row
                style={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}
              >
                <Col
                  sm="12"
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  {selectEspecialidades()}
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => {
            setTipoModal("")
            onSubmit()
            }}>
            Salvar
          </Button>
          <Button color="secondary" onClick={() => {
            setOpenModal(false)
            setTipoModal("")            
            setFormData(
              {
                nome: "",
                link_maps: "",
                link_instagram: "",
                link_facebook: "",
                ddd: "",
                telefone: "",
                endereco: "",
                bairro: "",
                cep: "",
                    cidade_id: { value: "", label: "" },
                especialidades: [],
                imagem: undefined
              })
            }}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalCredenciadosAdm;
