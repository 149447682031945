import logoMix from '../assets/images/logos/logo_header.png';

const ErrorPage = () => {
    return (
        <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            backgroundColor: '#fff',
            flexDirection: 'column',
            
        }}
        >
            <img src={logoMix} alt="logo" width={220} />
            <br />
            <h1>Ooops! Página não encontrada.</h1>
            <br />
            <h3>Erro 404</h3>
            <br />
            <h5>Desculpe, mas a página que você está procurando não existe.</h5>
            <br />
            <h5>Verifique se você digitou corretamente o endereço da página.</h5>
            <br />
            <h5>Se você chegou aqui através de um link, por favor, entre em contato conosco.</h5>
            <br />
           
        
        </div>
    );
    };

export default ErrorPage;