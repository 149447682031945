import React, {useState} from 'react'
import DataTable from 'react-data-table-component';
import { Edit2, Filter, Link, Trash2 } from 'react-feather';

import { Card, CardBody, Row, Col, Button, Label, Input } from 'reactstrap';
import ModalUsuarios from './modal';

const FranqueadosAdm = () => {

    const [tipoModal, setTipoModal] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [openFilter, setOpenFilter] = useState(false)
    const [dados, setDados] = useState([{
        titulo: "",
        conteudo: "",
        midia: "",
        script: "",    
    }])
    console.log(dados.midia)
    const handleOpenModal = (tipo) => {
        setTipoModal(tipo)
        setOpenModal(true)
    }

    const onSubmit = () => {
        
    }

    const columns = [
        {
            name: 'Título',
            selector: 'titulo',
            sortable: true,
            width: '10%'
        },
        {
            name: 'Conteúdo',
            selector: 'conteudo',
            sortable: true,
            width: '70%'
            
        },
        {
            name: 'Mídia',
            selector: 'midia',
            sortable: false,
            width: '10%',
            cell: row => <img src={row.midia} alt={row.titulo} style={{width: '70px'}} />
        },
        {
            name: 'Ações',
            selector: 'acoes',
            sortable: true,
            right: true,
            width: '10%',
            cell: row => <><Edit2 size={18} onClick={() => handleOpenModal("Editar")} />&nbsp;&nbsp;<Trash2 size={18}></Trash2></>
        },
    ];

    const data = [
        { titulo: 'Teste 1', conteudo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget aliquam ultricies, nunc nisl aliquet', midia: 'https://source.unsplash.com/random/100x70/?health', script: 'teste' },
        { titulo: 'Teste 2', conteudo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget aliquam ultricies, nunc nisl aliquet', midia: 'https://source.unsplash.com/random/100x70/?smile', script: 'teste' },
        { titulo: 'Teste 9', conteudo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget aliquam ultricies, nunc nisl aliquet', midia: 'https://source.unsplash.com/random/100x70  /?family', script: 'teste' },
        
    ];
    
    return (
    <>
        <ModalUsuarios 
        tipoModal={tipoModal} 
        openModal={openModal} 
        setOpenModal={setOpenModal} 
        dados={dados} 
        setDados={setDados} 
        onSubmit={onSubmit}
        />
    <br />
    <Row>
        <Card>
            <CardBody>
                <Row>
                    <Col sm='2'>
                <h4>Franqueados</h4>
                    </Col>
                    <Col sm='10' style={{
                        justifyContent: 'flex-end',
                        display: 'flex',
                        alignItems: 'right'
                    }}>
                <Button className='buttonSideFilter' onClick={() => setOpenFilter(!openFilter)}><Filter size={18} /></Button>&nbsp;        
                <Button size='sm' className='buttonSide' onClick={() => handleOpenModal("Criar")}><Link size={18} />&nbsp; Novo Franqueado</Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        
    </Row>
    <br />
    <div 
    style={{
        display: openFilter ? 'block' : 'none',
        transitionTimingFunction: 'ease-in-out',
        transitionDuration: '2s',
        transform: 'translateY(-10px)'
    }}
    >
    <Row>
        <Card >
            <CardBody>
                <Row>
                    <Col sm='1'>
                    <h5>Filtros</h5>
                    </Col>
                    <Col sm='11' >
                    <Row>
                        <Col sm='3'>
                        <Label>Nome</Label>
                        <Input type='text' />
                        </Col>
                        <Col sm='3'>
                        <Label>E-mail</Label>
                        <Input type='text' />
                        </Col>
                    </Row>                       
                    
                    </Col>
                </Row>
            </CardBody>
        </Card>
        
    </Row>
    </div>
    
    <Row>
        <Card>
            <CardBody>
                <Row>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        highlightOnHover
                        responsive
                        striped
                    />
                </Row>
            </CardBody>
        </Card>
    </Row>
                        
    </>
    )
    }

    export default FranqueadosAdm;