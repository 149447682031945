import api from "../api";

export const Paginador = async (body) => {
  return api
    .post(`credenciadoPdf/paginate`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const SaveDocumento = async (body) => {
  return api
    .post(`credenciadoPdf/save`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const GetDocumento = async (credenciado) => {
  return api
    .get(`credenciadoPdf/${credenciado}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const RemoveDocumento = async (id) => {
  return api
    .delete(`credenciadoPdf/remove/${id}`, {

      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};