import React from "react";
import bgImage from "../../../assets/images/bg/bgespecialidades.jpg";
import lupa from "../../../assets/images/bg/lupa.png";
import { Button, Row, Col } from "react-bootstrap";
import { Search } from "react-feather";

const Especialidades = () => {
  return (
    <>
      <div style={{ width: "100%", display: "flex" }}>
        <div className="especialidade-hero-web"
          style={{
            flexDirection: "column",
            width: "60%",
            height: "70vh",
            backgroundColor: "#1A4066",
            alignItems: "flex-start",
            justifyContent: "center",
            color: "white",
            fontSize: "200%",
            padding: "9rem 6rem 3rem 8rem",
            fontFamily: "Poppins",
          }}
        >
          <div className="especialidade-hero-web-image">
            São mais de 
            <span  style={{ fontFamily: "Poppins-Bold" }}>{" "}</span>
            especialidades ao seu alcance!
          </div>
        </div>
        <div className="especialidade-hero-web-image"
          style={{
            display: "inline-block",
            width: "40%",
            height: "70vh",
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
      <br />

      <div className="especialidade-hero-app" style={{ width: "100%" }}>
        <div
          style={{
            display: "inline-block",
            width: "100%",
            height: "22vh",
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div
          style={{
            marginTop: "-6px",
            flexDirection: "column",
            width: "100%",
            height: "17vh",
            backgroundColor: "#1A4066",
            alignItems: "flex-start",
            justifyContent: "center",
            color: "white",
            fontSize: "23px",
            padding: "3rem 1rem 0rem 1rem",
            fontFamily: "Poppins",
          }}
        >
          <div
            style={{
              textAlign: "center",
              height: "70vh",
              verticalAlign: "middle",
            }}
          >
            São mais de{" "}
            <span
              style={{
                fontFamily: "Poppins-Bold",
              }}
            >
              40
            </span>{" "}
            especialidades ao seu alcance!
          </div>
        </div>
      </div>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "0 6%",
        }}
      >
        <div
          style={{
            width: "90%",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "1.2rem",
            color: "#969696",
          }}
        >
          <Row style={{ textAlign: 'left' }}>
            <Col>
              <li>Acupuntura</li>
              <li>Angiologia</li>
              <li>Alergologia</li>
              <li>Cardiologia</li>
              <li>Cirurgia Torácica</li>
              <li>Cirurgia Vascular</li>
              <li>Cirurgia Geral</li>
              <li>Clínica Geral</li>
              <li>Dermatologia</li>
              <li>Endocrinologia</li>
              <li>Endodontia</li>
            </Col>
            <Col>
              <li>Fonoaudiologia</li>
              <li>Gastroenterologia</li>
              <li>Ginecologia</li>
              <li>Implantodontia</li>
              <li>Imunologia</li>
              <li>Neurologia Adulto</li>
              <li>Neurologia Pediátrica</li>
              <li>Neuropsicologia</li>
              <li>Nutrição</li>
              <li>Odontologia</li>
              <li>Odontopediatria</li>
            </Col>
            <Col>
              <li>Odontologia Cirúrgica</li>
              <li>Odontologia Clínica</li>
              <li>Obstetrícia</li>
              <li>Oncologia</li>
              <li>Oftalmologia</li>
              <li>Ortodontia</li>
              <li>Ortopedia</li>
              <li>Traumatológia</li>
              <li>Otorrinolaringologia</li>
              <li>Pediatria</li>
              <li>Psicologia</li>
            </Col>
            <Col>
              <li>Pneumologia</li>
              <li>Pneumopediatria</li>
              <li>Psiquiatria</li>
              <li>Estética</li>
              <li>Ultrassonografia</li>
              <li>Urologia</li>
              <li>Periodontia</li>
            </Col>
          </Row>
        </div>
      </div>
      <br />

      <div className="especialidade-hero-web" style={{ width: "100%", display: "flex" }}>
        <div
          style={{
            display: "inline-block",
            width: "40%",
            height: "70vh",
            backgroundColor: "#1CB1E2",
            zIndex: "1",
          }}
        >
          <img src={lupa} alt="lupa" style={{ width: "100%", marginTop: "-28px" }} />
        </div>
        <div
          style={{
            flexDirection: "column",
            width: "65%",
            height: "70vh",
            backgroundColor: "#1CB1E2",
            alignItems: "flex-start",
            justifyContent: "center",
            color: "white",
            fontSize: "2rem",
            padding: "5rem 6rem 3rem 8rem",
            fontFamily: "Poppins",
            paddingRight: "18%",
          }}
        >
          <div
            style={{
              fontSize: "2.3rem",
              lineHeight: "2.5rem",
              textAlign: "left",
              verticalAlign: "middle",
              fontFamily: "Poppins-Bold",
              marginBottom: "1rem",
            }}
          >
            Precisando agendar uma consulta ou exame?
          </div>
          <div
            style={{
              fontSize: "1.3rem",
              lineHeight: "1.5rem",
              textAlign: "left",
              verticalAlign: "middle",
              fontFamily: "Poppins",
              fontWeight: "500",
            }}
          >
            Encontre a especialidade desejada mais próxima de você!
          </div>
          <br />
          <Button variant="primary" size="lg" style={{ margin: "auto", marginBottom: "2rem", width: "45%", backgroundColor: "#1A4066", border: "none" }}>
            <Search></Search>&nbsp;&nbsp; Procurar
          </Button>
        </div>
      </div>

      <div className="especialidade-hero-app" style={{ width: "100%", color: "white" }}>
          <div
          style={{
            display: "inline-block",
            width: "100%",
            height: "70vh",
            backgroundColor: "#1CB1E2",
            zIndex: "1",
            padding: "5%"
          }}
        >
        <div
        style={{
          fontSize: "2.3rem",
          lineHeight: "2.5rem",
          textAlign: "left",
          verticalAlign: "middle",
          fontFamily: "Poppins-Bold",
          marginBottom: "1rem",
        }}
      >
            Precisando agendar uma consulta ou exame?
          </div>
          <div
            style={{
              fontSize: "1.3rem",
              lineHeight: "1.5rem",
              textAlign: "left",
              verticalAlign: "middle",
              fontFamily: "Poppins",
              fontWeight: "500",
            }}
          >
            Encontre a especialidade desejada mais próxima de você!
          </div>
          <br />
          <Button variant="primary" size="lg" style={{ margin: "auto", marginBottom: "2rem", width: "45%", backgroundColor: "#1A4066", border: "none" }}>
            <Search></Search>&nbsp;&nbsp;   Procurar
          </Button>
         
          <img src={lupa} alt="lupa" style={{ width: "120%", marginLeft: "-100px", marginTop: "-10%" }} /> 
          </div>
      </div>
    </>
  );
};

export default Especialidades;
