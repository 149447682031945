import api from "../api";


export const logar = async (body) => {
        return api
          .post(`auth/login`, body, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };
        
export const NovaSenha = async (body) => {
        return api
          .post(`auth/esqueci`, body, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };
        
export const Validar = async (body) => {
        return api
          .post(`auth/esqueciValidar`, body, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };

export const SalvarSenha = async (body) => {
        return api
          .put(`user/alterarSenha`, body, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => response)
          .catch((err) => err);
        }