import React, { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { PaginadorCredenciado } from '../../service/utils';

const SelectAsyncCredenciado = ({

  handleOnChangeSelect,
  placeholder,
  defaultValue,
  id,  
  cidade,
  tipo,
  className
}) => {

  const [filter, setFilter] = useState();
  const [noOptionsMessage, setNoOptionsMessage] = useState('')

  useEffect(() => {
    setFilter("");
  }, []);

  const onChange = option => {
    handleOnChangeSelect(option);
  }

  // Options - Select
  const loadOptions = async (inputValue, loadedOptions, { page }) => {
    try {
      // if (!filter) {
      //   setNoOptionsMessage('Selecione um Credenciado')
      //   return {
      //     options: []
      //   }
      // }
      
      const data = {
        page: 1,
        itensPerPage: 10,
        descricao: inputValue,
        cidade_id: cidade,
        apenas_credenciado: false
      }
      const response = await PaginadorCredenciado(data, 10, page);
      const options = response.data.content.map(item => {
        return {
          value: item.id,
          label: item.descricao
        }
      })
      
      return {
        options,
        hasMore: response.data.currentPage < response.data.countPages,
        additional: {
          page: inputValue ? 1 : page + 1
        }
      }
    } catch (e) {
      return {
        options: []
      }
    }
  }
  
  return (
    <div style={{ width: '100%'}}>
       <AsyncPaginate
        key={JSON.stringify(filter)}
        id={id}
        isClearable={true}
        className={className}
        classNamePrefix="select"
        value={defaultValue}
        loadingMessage={() => 'Carregando dados'}
        noOptionsMessage={() => noOptionsMessage || 'Nenhum registro encontrado'
      }
        loadOptions={loadOptions}
        onChange={onChange}
        isSearchable={true}
        placeholder='Credenciado'
        additional={{
          page: 1
        }}
        styles={{
          input:provided => ({ ...provided, zIndex: 9999, color: '#000', width: '250px' }),
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
      /> 
    </div>
  )
}

export default SelectAsyncCredenciado
