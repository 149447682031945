import Carousel from "react-bootstrap/Carousel";
import { useEffect, useState } from "react";
import { Paginador } from "../../service/banner";

const Carrousel = () => {

const [itens, setItens] = useState([{}])

const getBanners = () => {
    const body = {
        page: 1,
        itensPerPage: 10,
        descricao: null
    }
    Paginador(body).then((response) => {
            setItens(        
            response.data.data.content
        )
    }
    ).catch((err) => {
        console.log(err)
    })
}

useEffect(() => {
    getBanners()
}, [])

return (
    <Carousel
    ride="carousel"
    >
        {itens.map((item, index) => (
            <Carousel.Item key={index}>
                <div onClick={() => {
                    window.open(item.link, "_blank")  
                    console.log(item)
                }}
                    className="carrossel"
                 style={{
                    cursor: item.link !== "" ? "pointer" : "default",
                    backgroundImage: `url(${item.imagem})`, 
                    }}>
                    </div>
                <Carousel.Caption>
                    
                </Carousel.Caption>
            </Carousel.Item>
        ))}


    </Carousel>
  );
};

export default Carrousel;
