import React, { useState } from 'react';
import { Button, Card, Col, Row } from "react-bootstrap";
import { Input, Label } from "reactstrap";
import { FormCredenciado } from "../../../service/form";
import { toast } from 'react-toastify';
import { Spinner } from "reactstrap"

const SejaCredenciado = () => {

    const [empresa, setEmpresa] = React.useState('');
    const [segmento, setSegmento] = React.useState('');
    const [cnpj, setCnpj] = React.useState('');
    const [responsavel, setResponsavel] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [telefone, setTelefone] = React.useState('');
    const [spinner, setSpinner] = useState(false);

    const Enviar = () => {

        setSpinner(true);

        const body = {
            "responsavel": responsavel,
            "email": email,
            "empresa": empresa,
            "telefone": telefone,
            "segmento": segmento,
            "cnpj": cnpj
        }

        if (body.segmento.length === 0 || body.responsavel.length === 0 || body.empresa.length === 0 || body.email.length === 0 || body.telefone.length === 0) {
            toast.error('Preencha todos os campos para entrar em contato');
            setSpinner(false);
            return;
        }

        FormCredenciado(body).then((response) => {
            if (response.status === 200 || response.status === 201) {

                setEmpresa('');
                setSegmento('');
                setCnpj('');
                setResponsavel('');
                setEmail('');

                toast.success('Contato enviado com sucesso!');
                setSpinner(false);
                return;
            }

            setSpinner(false);
            toast.error('Erro ao enviar contato!');

        }).catch((err) => {
            toast.error('Erro ao enviar contato!');
            setSpinner(false);
        })
    }

    return (
        <>
            <Row className='justify-content-md-center seja-credenciado-row'>
                <Col sm="8">
                    <Row className='justify-content-md-center'>
                        <p style={{ textAlign: "center", fontSize: "2rem", fontWeight: 'bold' }}>Seja credenciado a Mix da Saúde</p>
                    </Row>
                    <Row className='justify-content-md-center'>
                        <p style={{ textAlign: "center" }}>
                            Faça como inumeras clinicas profissionais e empresas da região, seja credenciado a <strong>Mix da Saúde</strong>.
                        </p>
                    </Row>
                    <Row className='seja-credenciado-input'>
                        <p style={{ textAlign: "center" }}>
                            São mais de <strong>20.000 usuários</strong> que utilizam diariamente nossos benefícios.
                        </p>
                    </Row>
                    <Row className='seja-credenciado-input'>
                        <Col sm="6">
                            <Label className="inputNome">
                                Nome da Clínica / Empresa
                            </Label>
                            <Input
                                className="inputNome"
                                value={empresa}
                                onChange={(e) => setEmpresa(e.target.value)}
                            />
                        </Col>
                        <Col sm="6">
                            <Label
                                className="inputNome"
                            >
                                Segmento
                            </Label>
                            <Input
                                className="inputNome"
                                value={segmento}
                                onChange={(e) => setSegmento(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row className='seja-credenciado-input'>
                        <Col sm="6">
                            <Label
                                className="inputCnpj"
                            >
                                CNPJ
                            </Label>
                            <Input
                                className="inputCnpj"
                                value={cnpj}
                                onChange={(e) => setCnpj(e.target.value)}
                            />
                        </Col>
                        <Col sm="6">
                            <Label
                                className="inputNome"
                            >
                                Nome do Responsável
                            </Label>
                            <Input
                                className="inputNome"
                                value={responsavel}
                                onChange={(e) => setResponsavel(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row className='seja-credenciado-input'>
                        <Col sm="6">
                            <Label
                                className="inputTelefone"
                            >
                                Telefone p/ Contato
                            </Label>
                            <Input
                                className="inputTelefone"
                                value={telefone}
                                onChange={(e) => setTelefone(e.target.value)}
                            />
                        </Col>
                        <Col sm="6">
                            <Label
                                className="inputEmail"
                            >
                                E-mail
                            </Label>
                            <Input
                                className="inputEmail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row className='justify-content-md-center' style={{ padding: '2rem' }}>
                        { (spinner) ?                       <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "20px",
                            fontSize: "20px",
                            fontWeight: "bold",
                        }}
                      >
                        <Spinner animation="border" variant="primary" /> &nbsp; &nbsp;  Aguarde...
                      </div> : 
                        <Button className="btn-enviar seja-credenciado-btn" onClick={() => Enviar()}>Enviar</Button>
                    }
                    </Row>
                    <Row className='justify-content-md-center seja-credenciado-input'>
                        <p style={{ textAlign: "center" }}>
                            Em breve entraremos em contato para prosseguir com seu credenciamento.
                        </p>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default SejaCredenciado