import React from "react";
import logo from "../../assets/images/logos/logo_header.png";
import insta from "../../assets/images/icons/iconinsta.png";
import face from "../../assets/images/icons/iconface.png";
import logowhite from "../../assets/images/logos/logobranca.png";
import { Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {

  const navigate = useNavigate();

  return (
    <>    
    
    <div className="footer">
      {/* First Row */}
      <Row className="table-footer" >
        <Col sm="1" />        
        <Col sm="2">
          <p className="title-table-footer">VEM PARA MIX</p>
          <p style={{ cursor: 'pointer'}} onClick={() => { navigate("/seja-credenciado"); window.scrollTo(0, 0); }}>Seja um credenciado</p>
          <p style={{ cursor: 'pointer'}} onClick={() => { navigate("/credenciados"); window.scrollTo(0, 0); }}>Seja um franqueado</p></Col>
        <Col sm="2">
          <p className="title-table-footer">CONTATO</p>
          <p>0800 482 0800</p>
          <p>diretoria@mixdasaude.com.br</p>
        </Col>
        <Col sm="2">
          <p className="title-table-footer">INSTITUCIONAL</p>
          <p style={{ cursor: 'pointer'}} onClick={() => { navigate("/sobre"); window.scrollTo(0, 0); }}>Sobre a MIX</p>
          <p style={{ cursor: 'pointer'}} onClick={() => { navigate("/especialidades"); window.scrollTo(0, 0); }}>Especialidades</p>
          <p style={{ cursor: 'pointer'}} onClick={() => { navigate("/credenciados"); window.scrollTo(0, 0); }}>Rede Credenciadas</p>
          <p style={{ cursor: 'pointer'}} onClick={() => { navigate("/blog"); window.scrollTo(0, 0); }}>Blogs</p>
          <p style={{ cursor: 'pointer'}} onClick={() => { navigate("/assinatura"); window.scrollTo(0, 0); }}>Contratar agora</p>
        </Col>
        <Col sm="2">
          <p className="title-table-footer">ONDE ESTAMOS</p>
          <p>Sorriso-MT(Matriz)</p>
          <p>Av. Blumenau, 3284, Centro</p>
          <p>Lucas do Rio Verde-MT(Filial)</p>
          <p>Av. Mato Grosso, 2298,  </p>
          <p>Rio Verde</p>
          <p>Noval Mutum-MT(Filial)</p>
          <p>Av. Nova Mutum, 600N, Centro</p>
        </Col>
        <Col sm="2">
          <p className="title-table-footer">SIGA NAS REDES</p>
          <p>
            <a href="https://www.facebook.com/mixdasaude" target="_blank" rel="noreferrer">
              <img src={face} alt="facebook" style={{ width: "40px", height: "40px" }} />
            </a>
            <a href="https://www.instagram.com/mixdasaude/" target="_blank" rel="noreferrer">
              <img src={insta} alt="instagram" style={{ width: "40px", height: "40px", marginLeft: "10px" }} />
            </a>
          </p>
        </Col>
        <Col sm="1" />        
      </Row>

      {/* Second Row */}
      <Row className="footer-logo">
        <Col sm="12" className="footer-logo">
          <img src={logowhite} alt="logo" style={{ width: "200px", marginBottom: "30px" }} />
        </Col>
      </Row>

      {/* Third Row */}
      
    </div>
    <div>

      <Row className="footer-info">
          <Col sm="6" >
          <span style={{ fontFamily: "Poppins-Bold" }}>© 2023 Mix da Saúde Sistema Alternativo de Saúde.</span> Todos os direitos reservados
          </Col>
          <Col sm="3" >
          <span style={{ fontFamily: "Poppins-Bold" }}>Política de Privacidade</span>
          </Col>
          <Col sm="3" >
          Desenvolvido por {' '}
  <a href="http://datassive.com.br" target="_blank" rel="noopener noreferrer">
    <span style={{ fontFamily: "Poppins-Bold" }}>DATASSIVE</span>
  </a>
          </Col>
      </Row>
    </div>


    </>
  );
};

export default Footer;
